/*
    developed using code examples from:
    - https://www.hongkiat.com/blog/introjs-step-by-step-guide-tutorial/
    - https://github.com/usablica/intro.js/blob/master/example/programmatic/index.html

    options described here:
    - https://introjs.com/docs/intro/options/

    local storage for displaying the tutorial once:
    - https://www.hongkiat.com/blog/jquery-cookies/
*/

/* Usage
- include intro.js and css in your page template
      <!-- Intro.js CSS -->
      <link href='https://unpkg.com/intro.js@2.9.3/minified/introjs.min.css' rel='stylesheet' />
      <!-- Include intro.js, requires JQuery -->
      <script src="https://unpkg.com/intro.js@2.9.3/minified/intro.min.js"></script>
- if needed, define dedicated custom css (e.g. ".introjs_custom { text-align: justify;}" to format text) or override intro.js css (e.g. ".introjs-tooltip { width: 460px; }" to extend panel width)
- configure all page elements so they can be targeted by CSS selectors
- call "startIntro(true);" if you want to force the display even if the tutorial has already been completed, e.g. with a link: <a href="javascript:void(0)" onclick="startIntro(true)">Start guided tour</a>
- call "startIntro(false);" in main page loading, e.g. "$(document).ready(function() { startIntro(false; });" so it is played on first access only
*/

import introJs from 'intro.js'

function startIntro(override){

    // recover previous execution status, if any
    var name = 'IntroJS-Konnect';
    var value = localStorage.getItem(name);

    var intro = introJs();
    intro.setOptions({
        hidePrev: true,
        showBullets: true,
        hideNext: true,
        showStepNumbers: false,
        steps: [
            {
                intro: "<p class=\"as-title\"> Welcome to GeoKonnect!</p><p class=\"as-subheader\">This guided tour will help you discover the main features of the application.</p>",
                tooltipClass: "introjs_custom",
            },
/*
*/
           {
                element: document.querySelector('.as-sidebar--left'),
                intro: "The left sidebar lets you select a scenario, and show or hide the layers attached to it",
                position: 'right',
                tooltipClass: "introjs_custom",
           },
           {
                element: document.querySelector('.as-sidebar--right'),
                intro: "The right sidebar reflects all the filters that have been configured in the scenario for each one of its layers",
                position: 'left',
                tooltipClass: "introjs_custom",
           },
           {
                element: document.querySelectorAll('.button-close')[1],
                intro: "It is possible to hide both sidebars to give more screen space to the map",
                position: 'left',
                tooltipClass: "introjs_custom",
           },
           {
                element: document.querySelector('#scenario_title'),
                intro: "The first step is to select the scenario that best fits your needs using the drop down menu. A short description of the scenario objectives will be displayed, and the map layers associated with the scenario will be updated below the description",
                position: 'right',
                tooltipClass: "introjs_custom",
            },
            {
                element: document.querySelector('#layers_title'),
                intro: "All the active layers below are stacked on the map in a top-down order (the uppermost layers appear above the bottom layers)...",
                position: 'right',
                tooltipClass: "introjs_custom",
            },
            {
                element: document.querySelector('#coverage_top_intro'),
                intro: "... unless this toggle is switched on. In this case, the broadband coverage layers are 'promoted' to the top, partially masking the Point of Interest layers",
                position: 'right',
                tooltipClass: "introjs_custom",
            },
            {
                intro: "Layers are organized in several groups according to their type",
                tooltipClass: "introjs_custom",
            },
            {
                element: document.querySelectorAll('p.as-subheader')[0],
                intro: "Point-based layers are displayed on top, since they do not mask the other layers",
                position: 'right',
                tooltipClass: "introjs_custom",
            },
            {
                element: document.querySelectorAll('p.as-subheader')[1],
                intro: "Coverage layers come second, and are displayed with a semi transparent style so several layers can be selected simultaneously",
                position: 'right',
                tooltipClass: "introjs_custom",
            },
            {
                element: document.querySelectorAll('p.as-subheader')[2],
                intro: "Socio-economic data, being defined at the administrative level, are the last layers displayed over the background map, since they usually cover 100% of the area",
                position: 'right',
                tooltipClass: "introjs_custom",
            },
            {
                element: document.querySelectorAll('.as-checkbox')[0],
                intro: "Each layer is linked to a data source (eventually already pre-filtered)",
                position: 'right',
                tooltipClass: "introjs_custom",
            },
            {
                element: document.querySelectorAll('.as-checkbox')[1],
                intro: "A layer can be added or removed from the map using the checkbox placed to its left",
                position: 'right',
                tooltipClass: "introjs_custom",
            },
            {
                element: document.querySelectorAll('.as-checkbox')[1],
                intro: "If the layer allows it, popup panels can be switched on using the small arrow on the right. When active (dark arrow), a mouse click on any feature of this layer on the map will open a detailed information panel",
                position: 'right',
                tooltipClass: "introjs_custom",
            },
            {
                element: document.querySelectorAll('p.as-subheader')[0],
                intro: "The eye icon provides a quick way to globally show or hide all the active layers in the same group, so you do not lose your layer selection when you want to quickly view what the underlying layers look like",
                position: 'right',
                tooltipClass: "introjs_custom",
            },
           {
                element: document.querySelectorAll('p.as-subheader')[0],
                intro: "while the -/+ at the end of the line folds/unfolds the whole group to provide a faster access to the groups below",
                position: 'right',
                tooltipClass: "introjs_custom",
            },
/*
            {
                element: document.querySelectorAll('#step2')[0],
                intro: "Ok, wasn't that fun?",
                position: 'right'
            },
*/
        ]
    });

    if(value == null || override) {
        intro.start().oncomplete(function() {
            localStorage.setItem(name, 1)
        });
    };
}

/**
    var introbtn   = $('#startdemotour');

    introbtn.on('click', function(e){
      e.preventDefault();
      intro.start();
      $(this).hide();
    });

     * oncomplete re-display the button
     * hide by default since we don't need this functionality.
    intro.oncomplete(function() {
      if(introbtn.css('display') == 'none') {
        introbtn.show();
      }
    });
    intro.onexit(function() {
      if(introbtn.css('display') == 'none') {
        introbtn.show();
      }
    });
**/

export { startIntro };
