import axios from 'axios'
import iziToast from 'izitoast'
import carto from '@carto/carto.js'

import { current_scenario, 
    add_key_stat, remove_key_stat,
    renderLegendPolygon, renderLegendPoint, 
    register_dynamic_callbacks } from './map_nav.js';
import { map, map_invalidate, map_register_shape_filter_creation_callback } from './map_leaflet.js';
import { to_locale } from './map_utils.js';


var carto_client;                       // CARTO API consumer, initialized on each scenario loading
var bboxFilter;                         // carto.filter.BoundingBoxLeaflet() used by key stats carto.dataview.Formula() and widgets data views
var polygonFilter;                      // carto.filter.Polygon() used by key stats carto.dataview.Formula() and widgets data views
var circleFilter;                       // carto.filter.Circle() used by key stats carto.dataview.Formula() and widgets data views
var carto_layers = [];                  // Array of carto.layer.Layer() used to build the Leaflet CARTO layer on scenario loading, NOT MAINTAINED OVER TIME
var carto_sources = {};                 // dict linking layer source table with a matching carto.source.Dataset() TODO solve non unique issues that may arise
var carto_layer_input_ids = [];         // checkbox/radio controls <-> carto.layer.Layer() association, built on scenario loading
var carto_layer_infos_ids = [];         // info icon <-> carto.layer.Layer() association, built on scenario loading

// Implicit 'non-strict' global variables made explicit
var carto_layer_label_ids;
var carto_layer_style_ids;
var carto_group;
var query;
var carto_style;
var default_aggregation_field;
var default_aggregation_function;
var carto_source;
var carto_operations;
var carto_label_id;
var key_stats;
var cartoLayer;
var initial_include_list;
var dataView;
var bins;
var start;
var end;
var selectedCategoriesFromWidget;
var sourceQuery;
var endQuery;
var rangeQuery;
var minValue;
var maxValue;
var queryResult;

var cartoAxios;

async function carto_init() {

    // Catch unhandled promise rejections
    catchUnhandled();

    // define a CARTO-specific API client
    cartoAxios = axios.create({timeout: 30000});

    //TODO get API key from user login / scenarios list API call
    carto_client = new carto.Client({
        apiKey: carto_api_key,
        username: 'eutelsat-dev'
    });

    // initialize the 3 filters that can be used ONE AT A TIME on dataviews

    // set bboxFilter with new map bounding box
    bboxFilter = new carto.filter.BoundingBoxLeaflet(map);
    // initialize polygon+rectangle filter
    polygonFilter = new carto.filter.Polygon();
    polygonFilter.setPolygon({
        type: 'Polygon',
        coordinates: []
    });
    polygonFilter.on('polygonChanged', polygonData => {
        if (debug_mode) {
            console.log('polygonChanged');
        };
    });
    // initialize circular filter
    circleFilter = new carto.filter.Circle();
    circleFilter.setCircle({
        lat: 0.0,
        lng: 0.0,
        radius: 0.0
    });
    circleFilter.on('circleChanged', polygonData => {
        if (debug_mode) {
            console.log('circleChanged');
        };
    });

    // load list of layers in scenario and loop over it to build one Leaflet CARTO layer
    carto_load_layers();

    //clear existing dataViews if any
    carto_remove_keystatsdataviews();
    carto_remove_widgetsdataviews();

    // load list of dataviews //list of dataviews come from scenario to get access to data sources
    carto_load_keystatsdataviews();

    // add Leaflet CARTO layer to Leaflet map
    cartoLayer = carto_client.getLeafletLayer();
    cartoLayer.addTo(map);

    // register Leaflet Draw callback when a new polygon is defined (which updates the circle/polygon filter used by CARTO data views)
    map_register_shape_filter_creation_callback();

    /* ensure map area is filled appropriately with background tiles on load */
    map_invalidate();
};

function carto_load_layers() {

    // remove all exising layers, useful on scenario change
    carto_client.removeLayers(carto_client.getLayers());

    // see push(); concat() and unshift() to manage layers order in array here : https://stackoverflow.com/questions/351409/how-to-append-something-to-an-array
    carto_layers = [];
    carto_sources = [];
    carto_layer_input_ids = []; // stores input element ids to link layer with checkbox/radio control
    carto_layer_infos_ids = []; // same thing for layer info button
    carto_layer_label_ids = []; // same thing for layer labels used for legend
    carto_layer_style_ids = []; // stores HTML content built from layer CartoCSS for each layer_label_id

    const layer_groups = current_scenario['layer_groups'];

    // loop on each layer group
    if (layer_groups.length) { // handle situation where no group defined in JSON
        layer_groups.forEach(function (layer_group, group_index) {
            if (layer_group.length) { // handle empty group case
                carto_group = [];
                layer_group.forEach(function (layer_json, layer_index) {
                    // define common options here
                    var layer_options = {
                        id: layer_json['source'], // TODO replace by unique name (not yet defined in JSON model)
                    };
                    var newFeatureClickColumns = [];
                    var default_aggregation_columns = {};
                    var default_aggregation_field_out = 'Largest'; // IMPORTANT no space in name here => SQL bug CARTO
                    var out_field_suffix = '_';

                    if (layer_json['initial_filter']) {
                        query = `SELECT * FROM ${layer_json['source']} WHERE ${layer_json['initial_filter']} `;
                    } else {
                        query = `SELECT * FROM ${layer_json['source']}`;
                    };
                    console.log(query);
                    carto_source = new carto.source.SQL(query);
                    if (layer_json['has_planned']) {
                        carto_source.addFilter(planned_coverage_filter); // hide planned coverage on load
                    };
                    carto_style = new carto.style.CartoCSS(layer_json['cartocss']);
                    // add layers to map only if displayed = True
                    if (layer_json['displayed']) { layer_options['visible'] = true; }
                    else { layer_options['visible'] = false; };
                    // define aggregation fields and associated function
                    if (layer_json['aggregate'] && layer_json['type']==='point' && layer_json['feature_click_columns'].length) {
                        default_aggregation_field = layer_json['feature_click_columns'][0]; //TODO provide option to select other field than first
                        default_aggregation_field_out = default_aggregation_field.concat(out_field_suffix);
                        default_aggregation_function = 'mode';
                        newFeatureClickColumns.push(default_aggregation_field_out); // define new output field name
                        layer_options['featureClickColumns'] = newFeatureClickColumns;
                    }
                    else {
                        default_aggregation_field = '';
                        default_aggregation_field_out = default_aggregation_field.concat(out_field_suffix);
                        default_aggregation_function = '';
                        layer_options['featureClickColumns'] = layer_json['feature_click_columns'];
                    };
                     // set aggregation params depending on feature type (supported for point layers only)
                    //TODO decide what happens when layer_json['feature_click_columns'] is empty. For now, no aggregation done
                    if (layer_json['aggregate'] && layer_json['type']==='point' && layer_json['feature_click_columns'].length) {
                          // console.log('Setting aggregation params');
                          default_aggregation_columns[default_aggregation_field_out] = {
                                                                "aggregate_function": default_aggregation_function,
                                                                "aggregated_column": default_aggregation_field,
                                                                };
                          layer_options['aggregation'] = {
                                                    placement: 'centroid',
                                                    threshold: 5000,
                                                    columns: default_aggregation_columns,
                                                };
                      } else {
                            // console.log('No aggregation defined or possible');
                      };
                      // point layer aggregation doc: https://carto.com/developers/maps-api/guides/tile-aggregation/#aggregation-parameters
                      // console.log('Options sent when adding CARTO layer:' + layer_options);
                      const carto_layer = new carto.layer.Layer(carto_source, carto_style, layer_options);
                      carto_layer_input_ids['G' + group_index + 'L' + layer_index] = carto_layer;
                      carto_layer_infos_ids['G' + group_index + 'L' + layer_index + '_i'] = carto_layer;
                      carto_layer_label_ids['G' + group_index + 'L' + layer_index + '_l'] = carto_layer;
                      carto_group.unshift(carto_layer);
                      // keep track of sources so we can play with it (e.g. with dataviews)
                      carto_sources[layer_json['source']] = carto_source;
                      // keep track of metadata changes to update legend
                      carto_layer.on('metadataChanged', function(event) {
                      carto_label_id = 'G' + group_index + 'L' + layer_index + '_l';
                        event.styles.forEach(function (styleMetadata) {
                          switch(styleMetadata.getProperty()) {
                            case 'polygon-fill':
                              renderLegendPolygon(styleMetadata, this);
                              break;
                            case 'marker-fill':
                              renderLegendPoint(styleMetadata, this);
                              break;
                          }
                        }, carto_label_id); // Here is the trick: we are passing the label_id as this so it can be recovered in the called function
                      });
                });
                // adding group in the right order for CARTO layers => group 0 must be at the end of the array passed to CARTO
                carto_layers = carto_group.concat(carto_layers);
            };
        });
    };

  // concatenate all layers to display in a list passed to client which builds one Leaflet layer from list
  if (carto_layers.length) {
    carto_client.addLayers(carto_layers)
        .then(cartoAddLayersSuccessCallback)
        .catch(cartoAddLayersErrorCallback);
    };
};

//
/* CARTO client section */
//

var keyStatsDataViews = [];             // list of key stats (carto.dataview.Formula()), updated on scenario change
var widgetsDataViews = [];              // list of widgets dataview (carto.dataview.Histogram(), .Cartegory(), ...), updated on scenario change
let carto_views_filter_mode = 'bbox';   // ['bbox', 'circle', 'polygon'] used to enable the appropriate carto filters on all key figures & widgets data views

const planned_coverage_filter = new carto.filter.Category('status', { in: ['current'] }); // added or removed to coverage layers on planned coverage UI switch

///////////////////////////////
/* CARTO utilities functions */
///////////////////////////////

function cartoAddLayersSuccessCallback(result) {
            // console.log('Successfully added layers to client')
            // do not forget to register callbacks to dynamically created elements when everything is in place
            // called here because we need the layers to be available for registering DOM element is with carto layers
            register_dynamic_callbacks();
};

function cartoAddLayersErrorCallback(error) {
            //
            console.log('Failed adding layers to client' + error);
};

function carto_query_sql(query) {
    // example result for a SELECT MIN(col), MAX(col) FROM TABLE WHERE xxx:
    // {rows: [{min: 0, max: 98}], time: 0.664, fields: {min: {type: "number", pgtype: "float8"}, max: {type: "number", pgtype: "float8"}}, total_rows: 1}
    return cartoAxios.get('https://' + `eutelsat-dev.carto.com/api/v2/sql?api_key=${carto_api_key}&q=${query}`);
};

// function to call each time a new polygon has been defined (or reset to switch back to bbox filtering mode)
function carto_update_views_filter_mode(carto_views_new_filter_mode) {

    // update module 'global' variable from other modules
    carto_views_filter_mode = carto_views_new_filter_mode;

    if (debug_mode) {
        console.log(`Active filtering mode now set to: ${carto_views_filter_mode}`);
    };

    // update keystats filters
    if (keyStatsDataViews.length) {
        keyStatsDataViews.forEach(function (view, index) {
            // remove potential existing filters from each keystat data view
            view.removeFilter(bboxFilter);
            view.removeFilter(polygonFilter);
            view.removeFilter(circleFilter);
            // add the new filter to each keystat data view
            if (carto_views_filter_mode === 'bbox') {
                view.addFilter(bboxFilter);
            } else if (carto_views_filter_mode === 'circle') {
                view.addFilter(circleFilter);
            } else if (carto_views_filter_mode === 'polygon') {
                view.addFilter(polygonFilter);
            };
        })
    };

    // update widgets filters
    if (widgetsDataViews.length) {
        widgetsDataViews.forEach(function (view, index) {
            // remove potential existing filters from each widget data view
            view.removeFilter(bboxFilter);
            view.removeFilter(polygonFilter);
            view.removeFilter(circleFilter);
            // add the new filter to each widget data view
            if (carto_views_filter_mode === 'bbox') {
                view.addFilter(bboxFilter);
            } else if (carto_views_filter_mode === 'circle') {
                view.addFilter(circleFilter);
            } else if (carto_views_filter_mode === 'polygon') {
                view.addFilter(polygonFilter);
            };
        })
    };

};

////////////////////////////
/* key figures management */
////////////////////////////

function carto_load_keystatsdataviews() {

    // clearing the existing dataview has to be done by the calling function

    // see https://carto.com/developers/carto-js/reference/#cartooperation
    carto_operations = {
        'count': carto.operation.COUNT,
        'sum': carto.operation.SUM,
        'avg': carto.operation.AVG,
        'max': carto.operation.MAX,
        'min': carto.operation.MIN,
    };

    key_stats = current_scenario['key_stats'];

    // loop on each key stat
    if (key_stats.length) {
        key_stats.forEach(function (stats_json, index) {
            // define data sources for key figure
            if (carto_sources[stats_json['source']]) { // key stats only available if data source is present in layers
                // build entry in dedicated row
                add_key_stat(index, stats_json['label']);
                var keyStat = new carto.dataview.Formula(carto_sources[stats_json['source']], stats_json['column'], { operation: carto_operations[stats_json['operation']] });
                // add the BBOX filter to the view
                keyStat.addFilter(bboxFilter);
                // add error handler to view
                keyStat.on('error', handleCartoError);
                // finally add view to the client
                carto_client.addDataview(keyStat);
                // and make sure an update on the data source modifies UI elements on change
                keyStat.on('dataChanged', data => {
                    if (Boolean(document.getElementById('js-count-' + index))) {
                        if (data.result) {
                            document.getElementById('js-count-' + index).innerHTML = to_locale(data.result);
                        } else {
                            document.getElementById('js-count-' + index).innerHTML = "0";
                        };
                    };
                });
                // add to dataview list so we can play with it outside
                keyStatsDataViews.push(keyStat);
            }
            else {
            console.log("Unable to find data source for dataview");
            };
        });
    };
};

function carto_remove_keystatsdataviews() {

    if (keyStatsDataViews.length) {
        keyStatsDataViews.forEach(function (view, index) {
            remove_key_stat(index);
            view.removeFilter(bboxFilter);
            view.removeFilter(polygonFilter);
            view.removeFilter(circleFilter);
            view.disable(); // see https://github.com/CartoDB/carto.js/issues/2119
            // need to wait for promise to realize here, else dataview is still there for a while
            carto_client.removeDataview(view)
                .then(function(response) {
                    //if we wait here for the promise to be resolved, UI is slow to refresh in the key stats section
                    // is something else to do when promise is resolved ?
                    // maybe pop the view from the dataViews list ?
                })
                .catch(handleCartoError);
        });
        keyStatsDataViews = [];
    };
};

function carto_remove_widgetsdataviews() {

    if (widgetsDataViews.length) {
        widgetsDataViews.forEach(function (view, index) {
            view.removeFilter(bboxFilter);
            view.removeFilter(polygonFilter);
            view.removeFilter(circleFilter);
            // TODO check if we need to remove dataViewFilter too
            view.disable(); // see https://github.com/CartoDB/carto.js/issues/2119
            // need to wait for promise to realize here, else dataview is still there for a while
            carto_client.removeDataview(view)
                .then(function(response) {
                    // is something else to do when promise is resolved ?
                    // maybe pop the view from the dataViews list ?
                })
                .catch(handleCartoError);
        });
        widgetsDataViews = [];
    };
};

//////////////////////////////
/* CARTO widgets management */
//////////////////////////////

function carto_load_category_widget(widget_id, apply_id, source, column, operation, limit, operation_column) {

    var dataViewFilter;

    // TODO do this in a less hacky way
    if (source == 't_fr_pois_sirene_4326' && (column == 'covered_4g' || column == 'covered_broadband') ) {
        initial_include_list = ['no'];
    } else {
        initial_include_list = [];
    };

    // see https://carto.com/developers/carto-js/reference/#cartooperation
    carto_operations = {
        'count': carto.operation.COUNT,
        'sum': carto.operation.SUM,
        'avg': carto.operation.AVG,
        'max': carto.operation.MAX,
        'min': carto.operation.MIN,
    };

    // define data source
    dataView = new carto.dataview.Category(carto_sources[source], column, {
      operation: carto_operations[operation],
      limit: limit, // this is where we define how many categories are displayed in the widget, including others
    });

    // make sure widget is updated when data source changes
    dataView.on('dataChanged', function (newData) {
        categoryWidget.categories = newData.categories;
    });

    // add to datasource list so we can filter them using the bounding box with a loop
    widgetsDataViews.push(dataView);

    // define a new filter based on selected categories inside widget
    if (initial_include_list.length) {
        dataViewFilter = new carto.filter.Category(column, {in: initial_include_list}); //TODO check const
    } else {
        dataViewFilter = new carto.filter.Category(column, {}); //TODO check const
    };
    carto_sources[source].addFilter(dataViewFilter);

    const categoryWidget = document.getElementById(widget_id); //TODO check const
    categoryWidget.addEventListener('categoriesSelected', (event) => {
    if (event.detail.length == 0) {
        dataViewFilter.resetFilters();
        // hide apply button
        document.getElementById(apply_id).style.display = 'none';
    } else {
        // updating the filter here as in example code breaks the multiselection capability
        // a callback event has been added to an apply button that need to be shown/hidden at will
        //selectedEtablissements.setFilters({ in: event.detail});
        // show apply button
        document.getElementById(apply_id).style.display = 'inline-flex';
    }
    });

    {
      dataView.addFilter(bboxFilter);

      dataView.on('error', handleCartoError);

      carto_client.addDataview(dataView);
    };
    // add an event handler for resetting selection and apply button show/hide
    categoryWidget.addEventListener('categoriesSelected', (event) => {
    if (event.detail.length == 0) {
        dataViewFilter.resetFilters();
        // hide apply button
        document.getElementById(apply_id).style.display = 'none';
    } else {
        // updating the filter here as in example code breaks the multiselection capability
        // a callback event has been added to an apply button that need to be show/hidden at will
        //selectedEtablissements.setFilters({ in: event.detail});
        // show apply button
        document.getElementById(apply_id).style.display = 'inline-flex';
    }
    });
    /* add an event handler for applying widget selection to data source filter */
    const widget_apply_button = document.getElementById(apply_id); // TODO check const
    widget_apply_button.addEventListener("click",function() {
      console.log('Apply button fired');
      /* update scenario description */
        selectedCategoriesFromWidget = categoryWidget.getSelectedCategories()
            .then(categories => {
                // console.log(categories);
                dataViewFilter.setFilters({ in: categories})
            })
            .catch(error => console.error(error, {error}));
        // hide the apply button again
        widget_apply_button.style.display = 'none';
    });
};

function carto_load_histogram_widget(widget_id, source, column, binsValue, startValue, endValue) {

    // see https://carto.com/developers/carto-js/reference/#cartodataviewhistogram

    const histogramWidget = document.getElementById(widget_id); //TODO check const
    let variableFilter = null;
    let mapIsOut = false; // workaround for CARTO bug => keeps track of the reason why the widget receives a selectionChanged: 'clear selection' or map panning where no data matches

    // make sure we receive something valid
    bins = Number.isFinite(binsValue) ? binsValue : 10;
    start = Number.isFinite(startValue) ? startValue : null;
    end = Number.isFinite(endValue) ? endValue : null;

    // define data source
    dataView = new carto.dataview.Histogram(carto_sources[source], column, {
        bins: bins,
        start: start,
        end: end
    });

    // make sure widget is updated when data source changes
    dataView.on('dataChanged', function (newData) {
        const widgetData = newData.bins.map(bin => {
            return {
                start: bin.start,
                end: bin.end,
                value: bin.freq
            };
        });
        histogramWidget.data = widgetData;
        /* fixing reset bug on CARTO histogram widget:
        ** when a filter is set and the map is moved where no data can be found
        ** 'clear selection' is removed and does not come back when map moves again in an area
        ** where matching data is available even though the histogram is updated
        ** so we reinstate the filter boundaries by hand and the 'clear selection' button reappears
        */
        if (newData.totalAmount > 0) {
            mapIsOut = false;
            if (variableFilter) {
                histogramWidget.setSelection([variableFilter._filters.between.min,variableFilter._filters.between.max]);
            };
        } else {
            mapIsOut = true;
        };
    });

    // add to datasource list so we can filter them using the bounding box with a loop
    widgetsDataViews.push(dataView);

    // Filters

    histogramWidget.addEventListener('selectionChanged', event => {
        if (!event.detail) {
            // event.detail is null when
            // 1/ 'Clear selection' is clicked
            // 2/ moving the map where no matching data is present (which triggers the selectionChanged)
            if (variableFilter && !mapIsOut) {
                carto_sources[source].removeFilter(variableFilter);
                variableFilter = null;
            } else {
                // not sure what falls in this case since selectionChanged is not triggered when there is no selection
            };
        } else {
            // event.detail contains a JSON payload with selection when a custom selection is made on the widget
            if (variableFilter) {
                // a filter was already set, we just need to update
                const [min, max] = event.detail.selection;
                variableFilter.setFilters({ between: { min, max } });
            } else {
                // no filter was already set, we need to create one
                const [min, max] = event.detail.selection;
                variableFilter = new carto.filter.Range(column, { between: { min, max } });
                carto_sources[source].addFilter(variableFilter);
            };
        };
    });

    dataView.addFilter(bboxFilter);

    dataView.on('error', handleCartoError);

    carto_client.addDataview(dataView);
};

function carto_load_histogram_categorical_widget(widget_id, source, column, limit) {

    // see https://carto.com/developers/carto-js/reference/#cartodataviewhistogram

    const histogramWidget = document.getElementById(widget_id); //TODO check const

    // define data source
    dataView = new carto.dataview.Category(carto_sources[source], column, {
        operation: carto.operation.COUNT,
        limit: limit // this is where we define how many categories are displayed in the widget, including others
    });

    // make sure widget is updated when data source changes
    dataView.on('dataChanged', function (newData) {

        const widgetData = newData.categories.map(category => {
            return {
                category: category.name,
                value: category.value
            };
        });
        // widgetData comes sorted by value (carto.datavie.Category() output)
        // we sort it back to alphabetical order since it is a category-based histogram
        // see sort code here: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
        var mapped = widgetData.map(function(el, i) {
            return { index: i, value: el.category };
        });
        mapped.sort(function(a, b) { // TODO special case to add to keep 'Other' at the end of the list
            if (a.value > b.value) {
                return 1;
            } else if (a.value < b.value) {
                return -1;
            } else {
                return 0;
            };
        });
        var widgetDataAlphaSorted = mapped.map(function(el){
            return widgetData[el.index];
        });
        // now we can populate the widget
        histogramWidget.data = widgetDataAlphaSorted;
    });

    // add to datasource list so we can filter them using the bounding box with a loop
    widgetsDataViews.push(dataView);

    // Filters
    let variableFilter = null;

    histogramWidget.addEventListener('selectionChanged', event => {
    if (!event.detail && variableFilter !== null) {
      carto_sources[source].removeFilter(variableFilter);
      variableFilter = null;
    } else if (event.detail && variableFilter === null) {
      const range_selection = event.detail.selection;
      variableFilter = new carto.filter.Category(column, { in: range_selection });
      carto_sources[source].addFilter(variableFilter);
    } else {
        // TODO: fix the "Uncaught TypeError: Cannot read property 'selection' of null" that happens on the next line
        // when resetting the filter
      const range_selection = event.detail.selection;
      variableFilter.setFilters({ in: range_selection });
    }
    });

    dataView.addFilter(bboxFilter);

    dataView.on('error', handleCartoError);

    carto_client.addDataview(dataView);
};

function carto_load_rangeslider_widget(widget_id, source, column) {

    // https://carto.com/developers/carto-js/examples/#example-range-filter

    const rangeSlider = document.getElementById(widget_id); //TODO check const

    // get min and max values to adjust range slider bounds

    sourceQuery = carto_sources[source]['_query'];
    endQuery = sourceQuery.split('FROM')[1];
    rangeQuery = `SELECT MIN(${column}), MAX(${column}) FROM ${endQuery}`;
    minValue = 0;
    maxValue = 0;

    console.log(rangeQuery);
    carto_query_sql(rangeQuery)
        .then(function (response) {
            queryResult = response.data;
            minValue = queryResult['rows'][0]['min'];
            maxValue = queryResult['rows'][0]['max'];
            // avoid the following exception to happen "RangeSlider: Value 0 has to be between minValue (0) and maxValue (NaN)"
            // when maxValue is undefined (empty filter results)
            if (isNaN(maxValue)) {
                maxValue = minValue;
            };
            rangeSlider.setAttribute("min-value", minValue);
            rangeSlider.setAttribute("max-value", maxValue);
            rangeSlider.range = [minValue,maxValue];
            // TODO compute stepValue if required and depending on int vs float needs
            //        stepValue = (maxValue-minValue)/20;
            //        rangeSlider.setAttribute("step", stepValue);
        })
        .catch(error => console.error(error, {error}));

    // Filters
    let variableFilter = null;

    function rangeSliderUpdateCallback(event) {
        if (!event.detail && variableFilter !== null) {
            carto_sources[source].removeFilter(variableFilter);
            variableFilter = null;
        } else if (event.detail && variableFilter === null) {
            const minimumValue = parseInt(event.detail[0]);
            const maximumValue = parseInt(event.detail[1]);
            variableFilter = new carto.filter.Range(column, { between: { min: minimumValue, max: maximumValue } });
            carto_sources[source].addFilter(variableFilter);
        } else {
            const minimumValue = parseInt(event.detail[0]);
            const maximumValue = parseInt(event.detail[1]);
            variableFilter.setFilters({ between: { min: minimumValue, max: maximumValue } });
        }
    };

    rangeSlider.addEventListener('change', event => rangeSliderUpdateCallback(event));
    rangeSlider.addEventListener('changeStart', event => rangeSliderUpdateCallback(event));
    rangeSlider.addEventListener('changeEnd', event => rangeSliderUpdateCallback(event));

};

function carto_load_timeseries_widget(widget_id, source, column, aggregation, date_format) {

    // see https://carto.com/developers/carto-js/reference/#cartodataviewhistogram

    const timeseriesWidget = document.getElementById(widget_id); //TODO check const

    // make sure we receive something valid
    // TODO find a way to validate aggregation and dateFormat inputs

    // see https://carto.com/developers/carto-js/reference/#cartodataviewtimeaggregation
    carto_aggregations = {
        'auto': carto.dataview.timeAggregation.AUTO,
        'millenium': carto.dataview.timeAggregation.MILLENIUM,
        'century': carto.dataview.timeAggregation.CENTURY,
        'decade': carto.dataview.timeAggregation.DECADE,
        'year': carto.dataview.timeAggregation.YEAR,
        'quarter': carto.dataview.timeAggregation.QUARTER,
        'month': carto.dataview.timeAggregation.MONTH,
        'week': carto.dataview.timeAggregation.WEEK,
        'day': carto.dataview.timeAggregation.DAY,
        'hour': carto.dataview.timeAggregation.HOUR,
        'minute': carto.dataview.timeAggregation.MINUTE,
    };

    // define data source
    dataView = new carto.dataview.TimeSeries(carto_sources[source], column, {
        aggregation: carto_aggregations[aggregation],
//        offset: 1
    });

    // make sure widget is updated when data source changes
    dataView.on('dataChanged', newData => {
        const widgetData = newData.bins.map(bin => {
            return {
                start: bin.start*1000,
                end: bin.end*1000,
                value: bin.freq
            };
        });
        timeseriesWidget.data = widgetData;
        timeseriesWidget.timeFormat = date_format;
        selector = `#${widget_id} as-histogram-widget`;
        $(selector)[0].style = 'display:table';
        selector = `#${widget_id} as-widget-header`;
        $(selector)[0].style = 'margin-left:0px';
//        timeseriesWidget.timeFormatLocale = en_GB; // works, but .timeFormat above is simpler
    });

    // add to datasource list so we can filter them using the bounding box with a loop
    widgetsDataViews.push(dataView);

    // Filters
    let variableFilter = null;

    timeseriesWidget.addEventListener('selectionChanged', event => {
        console.log(event.detail);
        if (!event.detail && variableFilter !== null) {
          carto_sources[source].removeFilter(variableFilter);
          variableFilter = null;
        } else if (event.detail && variableFilter === null) {
          const [min, max] = event.detail;
          console.log(min);
          console.log(max);
          variableFilter = new carto.filter.Range(column, { between: { min, max } });
          carto_sources[source].addFilter(variableFilter);
        } else {
          const [min, max] = event.detail;
          console.log(min);
          console.log(max);
          variableFilter.setFilters({ between: { min, max } });
        }
    });

    dataView.addFilter(bboxFilter);

    dataView.on('error', handleCartoError);

    carto_client.addDataview(dataView);
};


function handleCartoError(error) {
    var notifyTooMany = function() {
        iziToast.show({
            class: 'warning',
            displayMode: 'once',  // avoid toast overflow!
            message: 'Too many points of interest, please filter…',
            title: 'Data',
        });
    };
    switch (error.errorCode) {
    case 'ajax:dataview:unknown-error':  // ?
    case 'generic:unknown-error':  // ?
        console.warn('Carto:', error.errorCode, error.message);
        // No notification
        break;
    case 'windshaft:analysis:unknown-error':  // query wait timeout
    case 'windshaft:dataview:unknown-error':  // query wait timeout
        console.warn('Carto:', error.errorCode, error.message);
        notifyTooMany();
        break;
    case 'windshaft:limit:over-platform-limits':  // SQL query timeout
        console.warn('Carto:', error.errorCode, error.message);
        notifyTooMany();
        break;
    default:
        console.error('GOTCHA:', error.errorCode, error.message, {error});
    };
};

function catchUnhandled() {
    window.addEventListener('unhandledrejection', event => {
        event.preventDefault();
        event.stopPropagation();
        if (('name' in event.reason) && (event.reason.name === 'CartoError')) {
            handleCartoError(event.reason);
        } else {
            console.error('Unhandled:', event.reason, {event});
        };
    });
};

export { carto_client, carto_sources, cartoLayer, query,
    bboxFilter, polygonFilter, circleFilter, planned_coverage_filter, carto_views_filter_mode, 
    carto_layer_input_ids, carto_layer_label_ids, carto_layer_infos_ids, carto_layer_style_ids, carto_label_id,
    carto_init, carto_update_views_filter_mode, carto_load_layers, 
    carto_load_keystatsdataviews, carto_remove_keystatsdataviews, 
    carto_remove_widgetsdataviews,
    carto_load_timeseries_widget, carto_load_rangeslider_widget, 
    carto_load_histogram_widget, carto_load_histogram_categorical_widget, 
    carto_load_category_widget };