//
// "/front/main.js" (i.e. app bundle after packaging) is called by skeleton_customer.j2 => on all pages
//

import '../scss/index.scss'  // load SCSS assets via webpack

import { nav_init, nav_start } from './map_nav.js'
import { getPage } from './map_utils.js'

//
// Script entry point
//

$(document).ready(function() {

    if (getPage() === 'map') {

        nav_init();

        //responsive call
        const responsiveContent = document.querySelector('as-responsive-content');
        responsiveContent.addEventListener('ready', () => {
            nav_start();
        });
    };
})
