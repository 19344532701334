(function(_g){(function(f){var r=(typeof require==='function'?require:function(name){return {"_":null}[name];});if (typeof exports==='object'&&typeof module!=='undefined'){module.exports=f(r)}else if(typeof define==='function'&&define.amd){define(["_"],f.bind(_g,r))}else{f(r)}})(function(require,define,module,exports){var _m=(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/*
here-json2geojson - Convert HERE JSON to GeoJSON object
Copyright 2017-present meggsimum
License: https://github.com/meggsimum/here-json2geojson/blob/master/LICENSE
*/

/**
 * Creates a GeoJSON Point geometry object
 * @param  {Array} coords The coordinates describing the point (e.g. [0,0])
 * @return {Object}       GeoJSON Point
 */
function createPoint(coords) {
  return {
    "type": "Point",
    "coordinates": coords || []
  };
}

/**
 * Creates a GeoJSON LineString geometry object
 * @param  {Array} coordArray The coordinates describing the point (e.g. [[0,0], [1,1]])
 * @return {Object}           GeoJSON LineString
 */
function createLineString(coordArray) {
  return {
    "type": "LineString",
    "coordinates": coordArray || []
  };
}

/**
 * Creates a GeoJSON Polygon geometry object
 * @param  {Array} coordArray The linestrings coordinate array describing the polygon (e.g. [[[0,0], [1,1], [2,2], [0,0]])
 * @return {Object}           GeoJSON Polygon
 */
function createPolygon(lineStringArray) {
  var polygon = {
    "type": "Polygon",
    "coordinates": lineStringArray
  };

  return polygon;
}

/**
 * Creates a GeoJSON Feature object.
 * @param  {Object} geometry   The GeoJSON geometry object
 * @param  {Object} properties Key-Value-Pairs added as feature attributes
 * @return {Object}            GeoJSON Feature
 */
function createFeature(geometry, properties) {
  return {
    "type": "Feature",
    "geometry": geometry,
    "properties": properties || {}
  };
}

/**
 * Creates a GeoJSON FeatureCollection object, which aggregates several features.
 * @param  {Array} features    Array of GeoJSON feature objects
 * @return {Object}            GeoJSON FeatureCollection
 */
function createFeatureCollection(features) {
  return {
    "type": "FeatureCollection",
    "features": features || []
  };
}

/**
 * Creates a GeoJSON compliant coordinates array from a string like "52.5132,13.3399".
 * @param  {String} coordString Coordinates string ("lat,lon")
 * @return {Array}             GeoJSON coordinate array ([lon,lat])
 */
function createCoords(coordString) {
  var rawPtArr = coordString.split(',');
  if (rawPtArr.length === 2) {
    return [parseFloat(rawPtArr[1], 10), parseFloat(rawPtArr[0], 10)];
  }
  return [];
}

exports.createPoint = createPoint;
exports.createLineString = createLineString;
exports.createPolygon = createPolygon;
exports.createFeature = createFeature;
exports.createFeatureCollection = createFeatureCollection;
exports.createCoords = createCoords;


},{}],2:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.readPlaces = exports.readWeatherConditions = exports.readTrafficIncidents = exports.readRoute = exports.readIsolines = undefined;

var _routing = require('./routing.js');

var _traffic = require('./traffic.js');

var _weather = require('./weather.js');

var _places = require('./places.js');

// comment out single unwanted functions for a smaller build
/*
here-json2geojson - Convert HERE JSON to GeoJSON object
Copyright 2017-present meggsimum
License: https://github.com/meggsimum/here-json2geojson/blob/master/LICENSE
*/
// This only exports the API functions for the standalone build
exports.readIsolines = _routing.readIsolines;
exports.readRoute = _routing.readRoute;
exports.readTrafficIncidents = _traffic.readTrafficIncidents;
exports.readWeatherConditions = _weather.readWeatherConditions;
exports.readPlaces = _places.readPlaces;


},{"./places.js":3,"./routing.js":4,"./traffic.js":5,"./weather.js":6}],3:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.readPlaces = undefined;

var _geojson = require('./geojson');

/**
 * Reads places-response object delivered by the HERE API and converts it
 * to a GeoJSON FeatureCollection holding the places as point features.
 * @param  {Object} herePlacesResponse HERE JSON for a place
 * @return {Object}                    GeoJSON FeatureCollection
 */
function readPlaces(herePlacesResponse) {
  var placesItems = herePlacesResponse.results.items;

  var placeFeatures = [];
  if (placesItems) {
    placesItems.forEach(function (place) {
      var placePointFeat = (0, _geojson.createFeature)((0, _geojson.createPoint)([place.position[1], place.position[0]]), place);
      placeFeatures.push(placePointFeat);
    });
  }

  return (0, _geojson.createFeatureCollection)(placeFeatures);
} /*
  here-json2geojson - Convert HERE JSON to GeoJSON object
  Copyright 2017-present meggsimum
  License: https://github.com/meggsimum/here-json2geojson/blob/master/LICENSE
  */

exports.readPlaces = readPlaces;


},{"./geojson":1}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.readIsolines = exports.readRoute = undefined;

var _geojson = require("./geojson");

/**
 * Reads route-response object delivered by the HERE API and converts it
 * to a GeoJSON FeatureCollection holding the route legs as line features.
 * @param  {Object} hereRouteResponse HERE JSON for a route
 * @return {Object}                   GeoJSON FeatureCollection
 */
function readRoute(hereRouteResponse) {
  var route = hereRouteResponse.response.route[0];

  var legFeatures = [];
  route.leg.forEach(function (routeLeg) {
    legFeatures.push(readRouteLeg(routeLeg));
  });

  return (0, _geojson.createFeatureCollection)(legFeatures);
}

/**
 * Converts a route leg JSON object to a GeoJSON feature.
 * @param  {Object} routeLeg HERE JSON for a route leg
 * @return {Object}          GeoJSON Feature
 * @private
 */
/*
here-json2geojson - Convert HERE JSON to GeoJSON object
Copyright 2017-present meggsimum
License: https://github.com/meggsimum/here-json2geojson/blob/master/LICENSE
*/

function readRouteLeg(routeLeg) {
  // initialize vertices array with route leg start point
  var vertices = [[routeLeg.start.mappedPosition.longitude, routeLeg.start.mappedPosition.latitude]];

  // add all route points
  routeLeg.maneuver.forEach(function (waypoint) {
    vertices.push([waypoint.position.longitude, waypoint.position.latitude]);
  });

  // close route with end point
  vertices.push([routeLeg.end.mappedPosition.longitude, routeLeg.end.mappedPosition.latitude]);

  // make GeoJSON Feature and apply the meta info as attributes
  return (0, _geojson.createFeature)((0, _geojson.createLineString)(vertices), {
    length: routeLeg.length,
    travelTime: routeLeg.travelTime
  });
}

/**
 * Reads isolines-response object delivered by the HERE API and converts it
 * to a GeoJSON FeatureCollection.
 * @param  {Object} hereIsolineResponse HERE JSON for isolines
 * @return {Object}                     GeoJSON FeatureCollection
 */
function readIsolines(hereIsolineResponse) {
  var isolines = hereIsolineResponse.response.isoline;
  var isolineFeatures = [];
  isolines.forEach(function (isoline) {
    var isolineShape = isoline.component[0].shape;
    var vertices = [];
    isolineShape.forEach(function (ptStr) {
      vertices.push((0, _geojson.createCoords)(ptStr));
    });

    var isolineFeat = (0, _geojson.createFeature)((0, _geojson.createPolygon)([vertices]), { "range": isoline.range });
    isolineFeatures.push(isolineFeat);
  });

  return (0, _geojson.createFeatureCollection)(isolineFeatures);
}

exports.readRoute = readRoute;
exports.readIsolines = readIsolines;


},{"./geojson":1}],5:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.readTrafficIncidents = undefined;

var _geojson = require('./geojson');

/**
 * Reads HERE traffic incidents and transforms them to a GeoJSON
 * FeatureCollection containing point features.
 * @param  {Object} trafficIncidentsResponse HERE JSON for traffic incidents
 * @param  {Boolean} addEndPoints            Should possible end points of incident be added to the FeatureCollection
 * @return {Object}                          GeoJSON FeatureCollection
 */
function readTrafficIncidents(trafficIncidentsResponse, addEndPoints) {
  var tiPointFeatures = [];

  trafficIncidentsResponse.TRAFFICITEMS.TRAFFICITEM.forEach(function (trItem) {
    var originGeo = trItem.LOCATION.GEOLOC.ORIGIN;

    // get clone of the traffic item
    var attrs = JSON.parse(JSON.stringify(trItem));
    // remove the location section from the attributes since this
    // is modeled as geometry in GeoJSON
    delete attrs.LOCATION;
    var tiPointFeat = (0, _geojson.createFeature)((0, _geojson.createPoint)([originGeo.LONGITUDE, originGeo.LATITUDE]), attrs);
    tiPointFeatures.push(tiPointFeat);

    if (addEndPoints) {
      // check if origin and end differ from each other => create end point
      var toGeo = trItem.LOCATION.GEOLOC.TO[0];
      if (originGeo.LONGITUDE !== toGeo.LONGITUDE || originGeo.LATITUDE !== toGeo.LATITUDE) {

        var tiEndPointFeat = (0, _geojson.createFeature)((0, _geojson.createPoint)([originGeo.LONGITUDE, originGeo.LATITUDE]), attrs);
        tiPointFeatures.push(tiEndPointFeat);
      }
    }
  });

  return (0, _geojson.createFeatureCollection)(tiPointFeatures);
} /*
  here-json2geojson - Convert HERE JSON to GeoJSON object
  Copyright 2017-present meggsimum
  License: https://github.com/meggsimum/here-json2geojson/blob/master/LICENSE
  */

exports.readTrafficIncidents = readTrafficIncidents;


},{"./geojson":1}],6:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.readWeatherConditions = undefined;

var _geojson = require('./geojson');

/**
 * Reads HERE traffic weather conditions and transforms them to a GeoJSON
 * FeatureCollection containing point features with weather info as attributes.
 * @param  {Object} weatherConditionsResponse HERE JSON for weather conditions
 * @return {Object}                           GeoJSON FeatureCollection
 */
function readWeatherConditions(weatherConditionsResponse) {
  var weatherCondPointFeatures = [];

  weatherConditionsResponse.observations.location.forEach(function (locItem) {
    var observationItem = locItem.observation[0];
    if (observationItem) {
      var wcPointFeat = (0, _geojson.createFeature)((0, _geojson.createPoint)([observationItem.longitude, observationItem.latitude]), observationItem);
      weatherCondPointFeatures.push(wcPointFeat);
    }
  });

  return (0, _geojson.createFeatureCollection)(weatherCondPointFeatures);
} /*
  here-json2geojson - Convert HERE JSON to GeoJSON object
  Copyright 2017-present meggsimum
  License: https://github.com/meggsimum/here-json2geojson/blob/master/LICENSE
  */

exports.readWeatherConditions = readWeatherConditions;


},{"./geojson":1}]},{},[2]);
var _r=_m(2);_g.hj2gj=_r;return _r;})})(typeof window!=='undefined'?window:(typeof global!=='undefined'?global:(typeof self!=='undefined'?self:this)));