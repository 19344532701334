// Airship web components, see https://carto.com/developers/airship/guides/getting-started/#usage-from-npm
import { defineCustomElements } from '@carto/airship-components/dist/loader';
import { readIsolines } from './here-json2geojson-0.2.0/hj2gj'
import * as turf from '@turf/turf'
import iziToast from 'izitoast'

import { map, wicket, info_popup, drawn_items, 
    map_init, map_relocate, map_draw_isoline } from './map_leaflet.js';
import { timer_start, timer_stop, timer_send, 
    handleApiError, customAxios, toast_init, to_locale } from './map_utils.js';
import { carto_init, carto_sources, carto_update_views_filter_mode, 
    circleFilter, bboxFilter, polygonFilter, planned_coverage_filter,
    carto_layer_style_ids, carto_layer_input_ids, carto_layer_infos_ids, carto_label_id, carto_layer_label_ids, 
    carto_remove_keystatsdataviews, carto_remove_widgetsdataviews, 
    carto_load_layers, carto_load_keystatsdataviews, carto_load_category_widget, 
    carto_load_timeseries_widget, carto_load_rangeslider_widget, 
    carto_load_histogram_widget, carto_load_histogram_categorical_widget, carto_views_filter_mode } from './map_carto.js';
import { startIntro } from './map_intro.js'

// Legacy global variables
var scenarios_data;
var current_scenario;

// Adjust values based on development progress
// TODO add other leaflet controls if required
// used by hide_globally_inactive_buttons() to .addClass("hidden") to DOM element
// except as_planned_disabled used by register_dynamic_callbacks() run each time a new scenario is loaded
{
    // banner tools
    var admin_tools_disabled = false;
    var market_overview_disabled = true;
    var data_upload_disabled = true;
    var country_selection_disabled = false;
    // map tools
    var info_button_disabled = true;
    var distance_to_coverage_disabled = true;
    var scoring_tool_disabled = true;
    var measure_disabled = false;
    var leaflet_draw_disabled = false;
    var isoline_distance_disabled = false;
    var isoline_time_disabled = false;
    // left sidebar tools
    var as_planned_disabled = false;
};

let max_dist = 50000;  // used for isoline max travel distance (in meters)
let max_time = 3600;  // used for isoline max travel time (in seconds)

// called once from $(document).ready() to load scenarios data from API associated with selected country and logged user
async function load_user_scenarios() {
    let start_time;  // GA timing
    let time_delta;  // GA timing
 
    let payload;  // API promise + answer

    let dropdown;  // scenario dropdown menu
    
    // API call happens here, now with async / await so we can move the following steps outside of this function

    // https://developers.google.com/analytics/devguides/collection/analyticsjs/user-timings#measuring_time
    start_time = timer_start();

    try {
        payload = await customAxios.get("user/scenarios");
    } catch(error) {
        payload = null;
        // catch API call errors here
        handleApiError(error);
    }

    // stop timer
    time_delta = timer_stop(start_time);
    timer_send('scenario_get', current_country, time_delta, current_user);

    // get dropdown DOM node
    dropdown = document.querySelector('#scenario_dropdown');

    // we will use the description & layer data somewhere below
    scenarios_data = payload.data['payload'];
    // just take what we need here from API answer and remap names
    var content_for_dropdown = scenarios_data.map(function(obj) {
        return {
            text: obj.name,
            value: obj.name_
        };
    });
    // populate dropdown
    dropdown.options = content_for_dropdown;
    // and initialize map view with data from initial scenario
    current_scenario = scenarios_data[0];
    /* set new default in dropdown based on first scenario in list */
    document.getElementById('scenario_dropdown').setAttribute('default-text',current_scenario['name']);
    /* update scenario description */
    document.getElementById('scenario_description').innerHTML = current_scenario['desc'];
};

// called to mask from the UI toolbar buttons that are not relevant according to available features
function hide_globally_inactive_buttons() {

    // header toolbar buttons
    if (admin_tools_disabled) {
        $(".as-button-tool.item-0").addClass("hidden");
    };
    if (market_overview_disabled) {
        $(".as-button-tool.item-1").addClass("hidden");
    };
    if (data_upload_disabled) {
        $(".as-button-tool.item-2").addClass("hidden");
    };
    if (country_selection_disabled) {
        $(".as-button-tool.item-4").addClass("hidden");
    };
};

// called to mask from the UI map controls that are not relevant according to available features (requires map)
function hide_leaflet_inactive_buttons() {
    // Leaflet map controls
    if (info_button_disabled) {
        $("img.btinfo").addClass("hidden");
    };
    if (distance_to_coverage_disabled) {
        $("img.btbbdist").addClass("hidden");
    };
    if (scoring_tool_disabled) {
        $("img.btscoring").addClass("hidden");
    };
    if (measure_disabled) {
        $("a.leaflet-control-draw-measure").addClass("hidden");
    };
    if (leaflet_draw_disabled) {
        $("img.btdrawing").addClass("hidden");
    };
    // custom additional 'draw' tools
    if (isoline_distance_disabled) {
        $(".leaflet-draw-draw-isodistance").addClass("hidden");
    };
    if (isoline_time_disabled) {
        $(".leaflet-draw-draw-isotime").addClass("hidden");
    };
};

// called to mask from the UI everything that is not relevant according to scenario
function hide_scenario_inactive_buttons() {

    // show/hide export buttons according to scenario
    if (current_scenario['exports']['customers'].length) {
        $("#export-client").parent().show();
    } else {
        $("#export-client").parent().hide();
    };
    if (current_scenario['exports']['businesses'].length) {
        $("#export-company").parent().show();
    } else {
        $("#export-company").parent().hide();
    };
    if (current_scenario['exports']['areas'].length) {
        $("#export-admin").parent().show();
    } else {
        $("#export-admin").parent().hide();
    };
    if (current_scenario['exports']['all'].length) {
        $("#export-all").parent().show();
    } else {
        $("#export-all").parent().hide();
    };
};


// Implicit 'non-strict' global variables made explicit
var start_time;
var time_delta;
var legend_type;
var field_renaming_dict;
var callback_layers_label_list;
var html_code;
var group_name;
var bucket;
var group_type;
var category;
var group_type_mapping;
var layer_name;
var label_name;
var info_name;
var group_index;
var layer_index;
var layer_group;
var layer_content;
var layer_label;
var row;
var div_a;
var switch_a;
var label_a;
var div_b;
var switch_b;
var label_b;
var unit;
var export_type;
var export_format;
var source_tables;
var source_table;
var initial_filter;
var filters_list;
var bbox;
var wkt;
var hide;
var layer_label_id;
var is_radio_group;
var layer_title_component;
var layer_input;
var layer_legend_component;
var layer_input_status;
var layer_group_id;
var layer_filter_content;
var layer_filter_component;
var legend_content;
var parsed;
var filter_sql;
var keyfigures_values_row;
var layers_box;
var widgets_box;
var scenario_description;
var layers_list;
var parsed_attrs;
var circle;
var attr_cleaned;
var attr_key;
var attr_value;
var content;
var number;
var value_str;

var group_types_mapping = {
    'checkbox': {
        'element': 'div',
        'element-class': 'as-checkbox',
        'input-class': 'as-checkbox-input',
        'input-type': 'checkbox',
    },
    'radio': {
        'element': 'li',
        'element-class': 'as-radio',
        'input-class': 'as-radio__input',
        'input-type': 'radio',
    },
};

// map tools status, to be maintained as mutually exclusive
{
    var notool_mode = true;         // when no map tool is selected
    var info_mode = false;          // local info on map click
    var distance_mode = false;      // distance to... on map click
    var measure_mode = false;       // measure on map with polyline
    var scoring_mode = false;       // local scoring on map click
    var draw_mode = false;          // selection by polygon / circle
    var polygon_mode = 'bbox';      // can be 'bbox', 'polygon', 'distance' or 'time'
    var isolineGeojson;
};

// TODO define layer group names in JSON schema, static for now
var group_labels = ['Points of interest', 'Broadband coverage', 'Socio-economic data'];
// TODO define layer group classes in JSON schema, static for now
var group_types = ['checkbox', 'checkbox', 'radio'];

var callback_layers_toggle_list = [];   // list of DOM element ids that have/need a callback to hide/show corresponding layer
var callback_layers_info_list = [];     // list of DOM element ids that have/need a callback to control info window source on click
var callback_groups_eye_list = [];      // list of DOM element ids that have/need a callback to control the group-level display toggle

var groups_layers_list = {};            // keep track of list of carto layers to be controlled by eye icon in each group
var coverage_layers_list = {};          // same thing, but for coverage layers only so we can move them to the top

// https://codersblock.com/blog/javascript-string-replace-magic/
// field names in PostGIS tables are not user-friendly, so rename them on the fly
function field_rename(field_name_source) {

    let field_name_pretty = '';

    // TODO: share renaming dict with Python backend code for the export feature
    field_renaming_dict = {
        'customer_name': 'distributor',
    };

    if ( Object.keys(field_renaming_dict).includes(field_name_source) ) {
        field_name_pretty = field_renaming_dict[field_name_source];
    } else {     // default basic rule: replace all underscores with a space
        field_name_pretty = field_name_source.replace(/_/g, ' ');
    };
    return field_name_pretty;
};

function nav_init() {

    // initialize Airship custom elements
    defineCustomElements(window);

    // hide things that are globally disabled
    hide_globally_inactive_buttons();
    
    // Initialize toast settings for user notification
    toast_init();

    // register callbacks on DOM elements that will not be destroyed on scenario change
    // this function is called only once from here
    register_static_callbacks();
};

// main stuff starts when page finishes loading
async function nav_start() {

    let scenarios_loaded_promise;
    let carto_initialized_promise;

    // start spinner
    $("#download-in-progress").addClass('visible');

    try {
        // we get all the data for all the scenarios in one API call,
        // and update the dropdown menu content from the API answer
        scenarios_loaded_promise = await load_user_scenarios() // blocking call, we need current scenario for a lot of stuff

        // map setup from current scenario
        map_init();
        // hide UI components needing map instance
        hide_leaflet_inactive_buttons();
        // hide UI components according to current scenario
        hide_scenario_inactive_buttons();

        // https://developers.google.com/analytics/devguides/collection/analyticsjs/user-timings#measuring_time
        start_time = timer_start();  

        // load the data layers for the first scenario in the list
        carto_initialized_promise = await carto_init();  // blocking call, we need widgets to be built to register callbacks

        // stop timer
        time_delta = timer_stop(start_time);
        timer_send('scenario_load', current_country + ' - ' + current_scenario['name'], time_delta, current_user);

        // stop spinner
        $("#download-in-progress").removeClass('visible');

    } catch(error) {
        // catch API call errors here
        handleApiError(error);
    }

    // dynamic elements callbacks are registered by populate_layer_box()
    // called once from here and in dropdown menu change callback

    // update the layer box content
    populate_layer_box();
    // and the widgets section
    populate_widget_box();

    // play tutorial on first access
    startIntro(false);
    
    // now we define the update callback to be fired when a new scenario is selected
    var dropdown = document.querySelector('#scenario_dropdown');
    dropdown.addEventListener('optionChanged', function (event) {

        let layer_groups;

        // https://developers.google.com/analytics/devguides/collection/analyticsjs/user-timings#measuring_time
        start_time = timer_start();
        // Map tools cleanup
        tools_common_disable();
        //clear existing dataViews if any
        carto_remove_keystatsdataviews();
        carto_remove_widgetsdataviews();
        // event.detail contains the option selected in dropdown when firing the callback
        // access id of scenario selected in dropdown
        current_scenario = scenarios_data.filter(item => item.name_ === event.detail)[0];

        scenario_description = current_scenario['desc'];
        layer_groups = current_scenario['layer_groups'];

        // move map to new location according to scenario settings
        map_relocate();
        //console.log('New scenario selected, time to refresh the layers list. Selected option:', event.detail);
        hide_scenario_inactive_buttons();
        /* update scenario description */
        document.getElementById('scenario_description').innerHTML = scenario_description;
        /* update list of layers defined by scenario */
        carto_load_layers();
        /* load key statistics stats */
        carto_load_keystatsdataviews();
        // and update the layer box
        populate_layer_box();
        // and the widgets section where widgets data views will be loaded
        populate_widget_box();
        // stop timer
        time_delta = timer_stop(start_time);
        // send timing to GA
        timer_send('scenario_change', current_country + ' - ' + current_scenario['name'], time_delta, current_user);
        // Google Analytics https://developers.google.com/analytics/devguides/collection/analyticsjs/events
        // ga('send','event','scenario_change',current_country + ' - ' + current_scenario['name'],current_user, 1);
        _paq.push(['trackEvent', 'scenario_change', current_country + ' - ' + current_scenario['name'], current_user]); // TODO currently not tracked on Matomo side
    });

};

// call this each time user switches to a new map tool is activated to restore the default state
function tools_common_disable() {

    //disable all map tools when required by calling their own setup functions

    if (info_mode) {
        // will use the internal disabling function
        if (debug_mode) {
            console.info("Info at location was active - now disabled");
        };
        $(".btinfo")[0].click();
    };

    if (distance_mode) {
        // will use the internal disabling function
        if (debug_mode) {
            console.info("Distance to coverage was active - now disabled");
        };
        $(".btbbdist")[0].click();
    };

    if (measure_mode) {
        // will use the internal disabling function
        if (debug_mode) {
            console.info("Ruler was active - now disabled");
        };
        $(".leaflet-control-draw-measure")[0].click();
    };

    if (scoring_mode) {
        // will use the internal disabling function
        if (debug_mode) {
            console.info("Scoring was active - now disabled");
        };
        $(".btscoring")[0].click();
    };

    if (draw_mode && polygon_mode=='distance') {
        // will use the internal disabling function
        if (debug_mode) {
            console.info("Draw/Isodistance was active - now disabled");
        };
        // disable isodistance tool by 'clicking' on it
        $("#draw_isodistance")[0].click();
        // then 'click' on pencil icon to clear geojson layer + highlight
        $(".btdrawing")[0].click();
    };

    if (draw_mode && polygon_mode=='time') {
        // will use the internal disabling function
        if (debug_mode) {
            console.info("Draw/Isotime was active - now disabled");
        };
        // disable isotime tool by 'clicking' on it
        $("#draw_isotime")[0].click();
        // then 'click' on pencil icon to clear geojson layer + highlight
        $(".btdrawing")[0].click();
    };

    if (draw_mode && polygon_mode=='polygon') {
        // will call drawn_items.clearLayers(); reset "carto_views_filter_mode" to 'bbox';
        // and make ".leaflet-draw" invisible and remove "enabled" class from ".btdrawing"
        // will use the internal disabling function
        if (debug_mode) {
            console.info("Draw/Generic was active - now disabled");
        };
        // TODO need to remove 'enabled' class from all standard draw tools
        // then 'click' on pencil icon to clear geojson layer + highlight
        $(".btdrawing")[0].click();
    };

    if (draw_mode && polygon_mode=='bbox') {
        // will call drawn_items.clearLayers(); reset "carto_views_filter_mode" to 'bbox';
        // and make ".leaflet-draw" invisible and remove "enabled" class from ".btdrawing"
        // will use the internal disabling function
        if (debug_mode) {
            console.info("Draw/None was active - now disabled");
        };
        // only 'click' on pencil icon to clear geojson layer + highlight
        $(".btdrawing")[0].click();
    };

    // reset map tools modes to default
    notool_mode = true;
    info_mode = false;
    distance_mode = false;
    measure_mode = false;
    scoring_mode = false;
    draw_mode = false;
    polygon_mode = 'bbox';
};

// call this each time user switches to a new leaflet draw tool is activated to restore the default state
function tools_draw_clear() {

    let carto_views_new_filter_mode;

    if (debug_mode) {
        console.info("Cleaning up drawing tools");
    };

    // reset drawing layer
    drawn_items.clearLayers();
    carto_views_new_filter_mode = 'bbox';
    carto_update_views_filter_mode(carto_views_new_filter_mode);

    // disable standard drawing tools by 'clicking' on it
    if (polygon_mode == 'polygon') {
        // TODO disable / clear standard drawing tools
    };

    // disable isodistance tool by 'clicking' on it
    if (polygon_mode == 'distance') {
        $("#draw_isodistance")[0].click();
    };

    // disable isotime tool by 'clicking' on it
    if (polygon_mode == 'time') {
        $("#draw_isotime")[0].click();
    };

    // reset polygon mode
    polygon_mode = 'bbox';
};

// called each time a click is made on the corresponding map tools button => here, INFO
function tools_info_click(e) {

    // tool was active, lets just disable it
    if (info_mode) {
        // set new active mode
        notool_mode = true;
        info_mode = false;

        if (debug_mode) {
            console.info("Self-deactivation of : info at location");
        };
    }
    // user wants to switch tools, make some global cleanup to disable whatever was active
    else {
        // set all (other) map tools in disabled state
        // the currently active tool will disable itself
        tools_common_disable();

        // set new active mode
        notool_mode = false;
        info_mode = true;

        if (debug_mode) {
            console.info("Active map tool is now: info at location");
        };
        // TODO do something with the info tool (currently disabled)
    };

    // in all cases
    e.toggleClass("enabled"); // switch light/dark blue style
};

// called each time a click is made on the corresponding map tools button => here, DISTANCE
function tools_distance_click(e) {

    // tool was active, lets just disable it
    if (distance_mode) {
        // set new active mode
        notool_mode = true;
        distance_mode = false;

        if (debug_mode) {
            console.info("Self-deactivation of : distance to coverage");
        };
    }
    // user wants to switch tools, make some global cleanup to disable whatever was active
    else {
        // set all (other) map tools in disabled state
        tools_common_disable();

        // set new active mode
        notool_mode = false;
        distance_mode = true;

        if (debug_mode) {
            console.info("Active map tool is now: distance to coverage");
        };
    };

    // in all cases
    e.toggleClass("enabled"); // switch light/dark blue style
};

// called each time a click is made on the corresponding map tools button => here, SCORING
function tools_scoring_click(e) {

    // tool was active, lets just disable it
    if (scoring_mode) {
        // set new active mode
        notool_mode = true;
        scoring_mode = false;

        if (debug_mode) {
            console.info("Self-deactivation of : scoring");
        };
    }
    // user wants to switch tools, make some global cleanup to disable whatever was active
    else {
        // set all (other) map tools in disabled state
        tools_common_disable();

        // set new active mode
        notool_mode = false;
        scoring_mode = true;

        if (debug_mode) {
            console.info("Active map tool is now: scoring");
        };
    };

    // in all cases
    $(".radioscoring").toggleClass("visible"); // show/hide scoring toolbar
    e.toggleClass("enabled"); // switch light/dark blue style
};

// called each time a click is made on the corresponding map tools button => here, MEASURE
function tools_measure_click(e) {

    // tool was active, lets just disable it
    if (measure_mode) {
        // set new active mode
        notool_mode = true;
        measure_mode = false;

        if (debug_mode) {
            console.info("Self-deactivation of : ruler");
        };
    }
    // user wants to switch tools, make some global cleanup to disable whatever was active
    else {
        // set all (other) map tools in disabled state
        tools_common_disable();

        // set new active mode
        notool_mode = false;
        measure_mode = true;

        if (debug_mode) {
            console.info("Active map tool is now: ruler");
        };
    };

    // in all cases
    e.toggleClass("enabled"); // switch light/dark blue style
};

// called each time a click is made on the corresponding map tools button => here, DRAW
function tools_draw_click(e) {

    // tool was active, lets just disable it and set data view filtering mode back to bbox
    if (draw_mode) {

        tools_draw_clear();

        // set new active mode
        notool_mode = true;
        draw_mode = false;

        if (debug_mode) {
            console.info("Self-deactivation of : draw toolbar");
        };
    }
    // user wants to switch tools, make some global cleanup to disable whatever was active
    else {
        // set all (other) map tools in disabled state
        tools_common_disable();

        // set new active mode
        notool_mode = false;
        draw_mode = true;

        if (debug_mode) {
            console.info("Active map tool is now: draw toolbar");
        };
    };

    // in all cases
    $(".leaflet-draw").toggleClass("visible"); // show/hide draw tools toolbar
    e.toggleClass("enabled"); // switch light/dark blue style
};

// we need to disable isoline tools when standard Leaflet Draw tools are used
function tools_draw_standard_tools_click(e) {
     // tool group was already active, let the leaflet draw plugin handle the situation
    if (polygon_mode == 'polygon') {
        if (debug_mode) {
            console.info("Leaflet Draw doing its magic");
        }
    // user wants to switch tools, make some global cleanup to disable whatever was active
    } else {
        // set all (other) map tools in disabled state
        tools_draw_clear();

        polygon_mode = 'polygon';

        if (debug_mode) {
            console.info("Active map tool is now: isoline/generic");
        };
    };
};

// called each time a click is made on the corresponding map tools button => here, isoline distance
function tools_isoline_distance_click(e) {
    // tool was active, lets just disable it and set data view filtering mode back to bbox
    if (polygon_mode == 'distance') {

         // hide settings panel
         $("#map-maxdistance-panel").removeClass("visible");

        // disable map click events for isoline
        map.off('click', tools_isoline_map_click);

        // reset polygon mode
        polygon_mode = 'bbox';

        if (debug_mode) {
            console.info("Self-deactivation of : isoline/distance");
        };
    }
    // user wants to switch tools, make some global cleanup to disable whatever was active
    else {
        // set all (other) draw tools in disabled state
        tools_draw_clear();

        $("#map-maxdistance-panel").addClass("visible");

        // define new Draw mode for isoline
        polygon_mode = 'distance';

        // activate isoline click event handler
        map.on('click', tools_isoline_map_click);

        if (debug_mode) {
            console.info("Active map tool is now: isoline/distance");
        };
    };

    // in all cases
    e.toggleClass('leaflet-draw-toolbar-button-enabled'); // switch light/dark blue style (managed the same way as the Leaflet Draw toolbar)
};

// called each time a click is made on the corresponding map tools button => here, isoline time
function tools_isoline_time_click(e) {

    // tool was active, lets just disable it and set data view filtering mode back to bbox
    if (polygon_mode == 'time') {

         // hide settings panel
         $("#map-maxtime-panel").removeClass("visible");

         // disable map click events for isoline
        map.off('click', tools_isoline_map_click);

        // reset polygon mode
        polygon_mode = 'bbox';

         if (debug_mode) {
            console.info("Self-deactivation of : isoline/time");
        };
    }
    // user wants to switch tools, make some global cleanup to disable whatever was active
    else {
        // set all (other) map tools in disabled state
        tools_draw_clear();

        // show settings panel
        $("#map-maxtime-panel").addClass("visible");

        // define new Draw mode for isoline
        polygon_mode = 'time';

        // activate isoline click event handler
        map.on('click', tools_isoline_map_click);

        if (debug_mode) {
            console.info("Active map tool is now: isoline/time");
        };
    };
    // in all cases
    e.toggleClass('leaflet-draw-toolbar-button-enabled'); // switch light/dark blue style (managed the same way as the Leaflet Draw toolbar)
}

// called each time a click is made on the map when isoline mode (distance or time) is active
function tools_isoline_map_click(e) {

    let carto_views_new_filter_mode;

    // clear any previous selection on map click
    drawn_items.clearLayers();
    carto_views_new_filter_mode = 'bbox';
    carto_update_views_filter_mode(carto_views_new_filter_mode);

    const lng = e.latlng.lng;
    const lat = e.latlng.lat;

    // start spinner
    $("#download-in-progress").addClass('visible');

    // query proxified HERE isoline API with params received
    customAxios.post("data/isoline",
    {
        lng: lng,
        lat: lat,
        mode: polygon_mode,
        max_dist: max_dist,
        max_time: max_time
    })
    .then(function(response) {
        $("#download-in-progress").removeClass('visible')
        return response.data['payload'];
    })
    .then(function(payload) {
        // convert HERE API JSON as GeoJSON
        var here_content = payload['geojson'];
        var hereIsolinesResponse = JSON.parse(here_content);
        isolineGeojson = readIsolines(hereIsolinesResponse);
        // display isoline GeoJSON on map
        map_draw_isoline(isolineGeojson);
    })
    .catch((error) => {
        // catch API call errors here
        // stop spinner
        $("#download-in-progress").removeClass('visible');
        handleApiError(error);
    });
};

function populate_layer_box() {
      // TODO define layer group names in JSON schema, static for now at beginning of the file
      // we  need to wait for API pomise to be fulfilled, so we register this as a callback
      // we can also call it on dropdown change

      // get layers data that were returned by the API
      const layer_groups = current_scenario['layer_groups'];

      // drop previous layer box content
      document.getElementById('layers_box').innerHTML = '';

      if (layer_groups.length) {
          layer_groups.forEach(function (view, index) {
            //FIXME : AWFUL hack to avoid layers being displayed in the wrong group when no POI layer is defined
            if (layer_groups.length==2){
                add_layer_group(index, group_labels[index+1]);
            } else {
                add_layer_group(index, group_labels[index]);
            };
          });
      };
      // dynamic callback registration needs to happen when carto layers are available (Promise)
      // call done in carto stuff
};

function add_layer_group(group_index, group_label) {

    var layer_group = current_scenario['layer_groups'][group_index];
    var group_layers_list;

    callback_layers_toggle_list = [];
    callback_layers_info_list = [];
    callback_layers_label_list = [];
    callback_groups_eye_list = [];

    // add group only if it contains layers
    if (layer_group.length) {
        var layer_group_title = L.DomUtil.create('p', 'as-subheader');
        layer_group_title.innerHTML = group_label;

        var layer_group_eye_span = L.DomUtil.create('span', 'see_all');
        group_name = "eye_admin_" + group_index;
        layer_group_eye_span.id = group_name;
        callback_groups_eye_list.push(group_name);
        layer_group_title.appendChild(layer_group_eye_span);

        layers_box = document.getElementById('layers_box');
        layers_box.appendChild(layer_group_title);

        var layer_group_body = L.DomUtil.create('div', 'as-body');
        var layer_group_ul = L.DomUtil.create('ul'); // only for radio;

        // special case for coverage group
        if (group_label==='Broadband coverage') {
            row = L.DomUtil.create('div', 'as-row');
            div_a = L.DomUtil.create('div');
            switch_a = L.DomUtil.create('as-switch');
            div_a.id = 'coverage_top_intro';
            switch_a.id = 'coverage_top';
            switch_a.setAttribute("name", 'coverage_top');
            switch_a.setAttribute("title", 'coverage_top');
            switch_a.checked = false;
            label_a = L.DomUtil.create('label', 'as-caption');
            label_a.innerHTML = ' As top layers';
            div_b = L.DomUtil.create('div');
            div_b.id = 'coverage_planned_intro';
            switch_b = L.DomUtil.create('as-switch');
            switch_b.id = 'coverage_planned';
            switch_b.setAttribute("name", 'coverage_planned');
            switch_b.setAttribute("title", 'coverage_planned');
            label_b = L.DomUtil.create('label', 'as-caption');
            label_b.innerHTML = ' Planned coverage';

            div_a.appendChild(switch_a);
            div_a.appendChild(label_a);
            div_b.appendChild(switch_b);
            div_b.appendChild(label_b);
            row.appendChild(div_a);
            row.appendChild(div_b);

            layer_group_body.appendChild(row);
        };

        // check if we build a radio- or checkbox-based list
        group_type = group_types[group_index];
        group_type_mapping = group_types_mapping[group_type];

        // add 'None' as first choice for a radio group
        if (group_type==='radio') {
            var layer_none = L.DomUtil.create(group_type_mapping['element'], group_type_mapping['element-class']);
            var layer_input = L.DomUtil.create('input', group_type_mapping['input-class']);
            layer_name = 'G' + group_index + 'L' + '-1'; // using -1 as fake layer number in group
            layer_input.id = layer_name;
            layer_input.checked = true;
            layer_input.setAttribute("type", group_type_mapping['input-type']);
            layer_input.setAttribute("value", 'G' + group_index + 'L' + '-1');
            layer_input.setAttribute("name", 'G' + group_index);
            // keep track of input element so we can register a callback
            callback_layers_toggle_list.push(layer_name);
            // and add to parent element
            layer_none.appendChild(layer_input);
            var layer_label = L.DomUtil.create('label', 'as-caption');
            layer_label.setAttribute("for", layer_input.id);
            layer_label.innerHTML = 'None';
            layer_none.appendChild(layer_label);
            layer_group_ul.appendChild(layer_none);
        };
        // loop over list of layers
        group_layers_list = [];
        layer_group.forEach(function (layer, layer_index) {
            // div or li
            var layer_el = L.DomUtil.create(group_type_mapping['element'], group_type_mapping['element-class']);
            // input
            var layer_input = L.DomUtil.create('input', group_type_mapping['input-class']);
            layer_name = 'G' + group_index + 'L' + layer_index;
            layer_input.id = layer_name;
            layer_input.checked = layer.displayed; // takes value from JSON
            layer_input.setAttribute("type", group_type_mapping['input-type']);
            layer_input.setAttribute("value", 'G' + group_index + 'L' + layer_index);
            layer_input.setAttribute("name", 'G' + group_index);
            // keep track of input element so we can register a callback to show/hide layer
            callback_layers_toggle_list.push(layer_name);
            // and add to parent element
            layer_el.appendChild(layer_input);
            // keep track of layers in group for eye icon control
            group_layers_list.push(carto_layer_input_ids[layer_name]);
            // checkbox decoration
            if (group_type==='checkbox') {
                var checkbox_decoration = L.DomUtil.create('span', 'as-checkbox-decoration');
                var checkbox_svg = L.DomUtil.create('svg', 'as-checkbox-media');
                var checkbox_polyline =  L.DomUtil.create('polyline', 'as-checkbox-check');
                checkbox_polyline.setAttribute('points', '1.65093994 3.80255127 4.48919678 6.97192383 10.3794556 0.717346191');
                checkbox_svg.appendChild(checkbox_polyline);
                checkbox_decoration.appendChild(checkbox_svg);
                layer_el.appendChild(checkbox_decoration);
            };
            // label
            var layer_label = L.DomUtil.create('label', 'as-caption');
            label_name = 'G' + group_index + 'L' + layer_index + '_l';
            layer_label.id = label_name;
            layer_label.setAttribute("for", layer_input.id);
            layer_label.innerHTML = layer.displayed_name;
            // keep track of title element so we can register a callback to show/hide legend
            callback_layers_label_list.push(label_name);
            // info popup selection
            var popup_button = L.DomUtil.create('a', 'bt-layer-select');
            info_name = 'G' + group_index + 'L' + layer_index + '_i';
            popup_button.id = info_name;
            popup_button.setAttribute("href", "");
            popup_button.setAttribute("title", "Set this layer active for info panels");
            popup_button.innerHTML = "activate";
            // keep track of info element so we can register a callback to control info window data source
            callback_layers_info_list.push(info_name);

            layer_el.appendChild(layer_label);
            layer_el.appendChild(popup_button);

            if (group_type==='radio') {
                layer_group_ul.appendChild(layer_el);
            } else {
                layer_group_body.appendChild(layer_el);
            };
        });

        groups_layers_list[group_name] = group_layers_list;
        if (group_label==='Broadband coverage') {
            coverage_layers_list = group_layers_list;
        };

        if (group_type==='radio') {
            layer_group_body.appendChild(layer_group_ul);
        };
        layers_box.appendChild(layer_group_body);

        // register group visibility toggle callbacks
        callback_groups_eye_list.forEach(function (group_eye_id, group_index) {
            register_group_eye_callback(group_eye_id);
        });

        // register layers input callbacks
        callback_layers_toggle_list.forEach(function (layer_input_id, layer_index) {
            register_layer_toggle_callback(layer_input_id);
        });

        // register layers info callbacks
        callback_layers_info_list.forEach(function (layer_info_id, layer_index) {
            register_layer_info_callback(layer_info_id);
        });

        // register layers legend callbacks
        callback_layers_label_list.forEach(function (layer_label_id, layer_index) {
            register_layer_legend_callback(layer_label_id);
        });

    };
};

function add_key_stat(index, label) {

    var key_stat_h3 = L.DomUtil.create('h3', 'as-title');
    var key_stat_pn = L.DomUtil.create('p', 'as-title as-keyfigure-number');
    key_stat_h3.id = "js-h3-" + index;
    key_stat_pn.id = "js-count-" + index;
    key_stat_pn.innerHTML = 0;
    var key_stat_pl = L.DomUtil.create('p', 'as-body as-keyfigure-label');
    key_stat_pl.id = "js-label-" + index;
    key_stat_pl.innerHTML = label;
    keyfigures_values_row = document.getElementById('keyfigures_values_row');
    keyfigures_values_row.appendChild(key_stat_h3).appendChild(key_stat_pn);
    keyfigures_values_row.appendChild(key_stat_h3).appendChild(key_stat_pl);
};

function remove_key_stat(index) {

      document.getElementById("js-h3-" + index).remove();
};

// callbacks which apply to dynamically created elements
// they need to be applied after element creation
function register_dynamic_callbacks() {

    if (debug_mode) {
        console.log('registering dynamic callbacks');
    };

    if (as_planned_disabled) {
        $("as-switch#coverage_planned")[0].disabled=true;
    };

    //subheader open/close
    $(function(){
        $(".as-subheader").not("[static]").on("click",function(){
            event.preventDefault();
            $(this).next().slideToggle();
            $(this).toggleClass("hide");
            return false;
        });
    });

    // layer group eye click
    $(function(){
        $(".see_all").not("[static]").on("click",function(){
            return false;
        });
    });

    // coverage_top click
    $(function(){
        $("#coverage_top").on("change",function(event){
            move_coverage_layers(event.target.checked);
            return false;
        });
    });

    // coverage_planned click
    $(function(){
        $("#coverage_planned").on("change",function(event){
            switch_coverage_planned(event.target.checked);
            return false;
        });
    });

};

// these callbacks are registered on page load only since elements are not destroyed live
function register_static_callbacks() {

    // console.log('registering static callbacks');

    // Intro.js link in help menu
    $(function(){
        $("#help_intro_run").on("click",function(){
            startIntro(true);
            $(".box-option").hide();
            return false;
        });
    });

    // Help pane link in help menu
    $(function(){
        $("#help_content_run").on("click",function(){
            $("#help_content").removeClass("hide");
            $(".box-option").hide();
            return false;
        });
    });

    // Help pane (hide on click)
    $("#help_content").on("click",function(){
        $(function(){
            $("#help_content").addClass("hide");
            return false;
        });
    });

    //subheader open/close
    $(function(){
        $(".as-subheader[static]").on("click",function(){
            event.preventDefault();
            $(this).next().slideToggle();
            $(this).toggleClass("hide");
            return false;
        });
    });

    // layer group eye click
    $(function(){
        $(".see_all[static]").on("click",function(){
           $(this).toggleClass("hide");
            return false;
        });
    });

    //open boxoption
    var $allLinks = $(".as-button-tool.item-foldable");
    $(".box-option").hide();
    $(function(){
        $(".as-button-tool.item-foldable").on("click",function(){
            event.preventDefault();
            // this = as-button-tool item-3 item-foldable
            // $allLinks.not(this).next() = div.boxoption
            $(this).next().slideToggle();
            $allLinks.not(this).next().hide();
            return false;
        });
    });

    // left toolbar show/hide
    $(function(){
        $(".as-sidebar--left .button-close").on("click",function(){
            $(".as-sidebar--left").toggleClass("hide");
            setTimeout(function(){ map.invalidateSize()}, 400);
            return false;
        });
    });

    // right toolbar show/hide
    $(function(){
        $(".as-sidebar--right .button-close").on("click",function(){
            $(".as-sidebar--right").toggleClass("hide");
            setTimeout(function(){ map.invalidateSize()}, 400);
            return false;
        });
    });

    // isoline panel to define maximum distance
    $('input[type=radio][name=maxdistance]').change(function() {
        // set new max travel distance used by map click callback (())
        max_dist = this.value;
        if (debug_mode) {
            console.log(`New maximum travel distance is ${max_dist} m`);
        };
    });

    // isoline panel to define maximum time
    $('input[type=radio][name=maxtime]').change(function() {
        // set new max travel time used by map click callback (())
        max_time = this.value;
        if (debug_mode) {
            console.log(`New maximum travel time is ${max_time} s`);
        };
    });

    //export-company, export-client, export-admin, export-all
    $(function(){
        $(".export-type").on("click",function(){

            // fold the export panel back
            $("div.box-option").hide();

            // manage export selection
            export_type = $("input[name='exporttype']:checked").val(); // businesses / customers / areas / all
            export_format = $("input[name='exportformat']:checked").val(); // excel / csv

            // console.log(current_scenario);

            if (export_type === 'customers') {
                source_tables = current_scenario['exports'][export_type];
            } else if (export_type === 'businesses') {
                source_tables = current_scenario['exports'][export_type];
            } else if (export_type === 'areas') {
                source_tables = current_scenario['exports'][export_type];
            } else if (export_type === 'all') {
                source_tables = current_scenario['exports'][export_type];
            } else {
                console.log('Wrong export type received');
                source_tables = [];
            };

            // TODO iterate over list instead of using the first one only (BETA)
            if (source_tables.length) {
                source_table = source_tables[0]['table'];
                initial_filter = source_tables[0]['initial_filter'];
            } else {
                source_table = '';
                initial_filter = '';
            };

            console.log(`Will export ${export_type} (${source_table}) with initial filter ${initial_filter}, requested as ${export_format}`);

            // prepare statement to be added in query after the WHERE bbox=()
            if (initial_filter.length) {
                initial_filter = `AND ${initial_filter}`;
            };

            if (source_table.length) {
                // no need to start spinner since we now have Celery background tasks
                // $("#download-in-progress").addClass('visible');

                // build SQL query
                var columns_filters = '';
                filters_list = carto_sources[source_table].getFilters();
                filters_list.forEach(function (filter, filter_index) {
                    filter_sql = filter.$getSQL();
                    // check if there is something set as filter
                    console.log(filter_sql);
                    if (filter_sql.length) {
                        if (columns_filters.length) {
                            columns_filters = columns_filters + ' AND ' + filter_sql;
                        } else {
                            columns_filters = ' AND ' + filter_sql;
                        };
                    };
                });

                // collect data sources filter
                if (carto_views_filter_mode === 'bbox') {
                    bbox = bboxFilter.getBounds();
                    // build WKT from bbox
                    wkt = "POLYGON(("
                    + bbox.west + " " + bbox.north +","
                    + bbox.east + " " + bbox.north +","
                    + bbox.east + " " + bbox.south +","
                    + bbox.west + " " + bbox.south + ","
                    + bbox.west + " " + bbox.north + "))";
                } else if (carto_views_filter_mode === 'circle') {
                    circle = circleFilter.getCircle();
                    var circleCenter = [circle.lng,circle.lat];
                    var circleRadius = circle.radius;
                    //Turf Buffer
                    var options = {steps: 64, units: 'meters'};
                    var circlePoint = turf.point(circleCenter);
                    var circleBuffered = turf.circle(circlePoint, circleRadius, options); // issue with radius when using turf.buffer(), see https://github.com/Turfjs/turf/issues/1470
                    wkt = wicket.read(JSON.stringify(circleBuffered)).write();
                } else if (carto_views_filter_mode === 'polygon') {
                    wkt = wicket.fromObject(polygonFilter.getPolygon()).write();
                };

                if (debug_mode) {
                    console.log(wkt);
                };

                // Google Analytics https://developers.google.com/analytics/devguides/collection/analyticsjs/events
                // ga('send','event','export_' + export_format,current_country + ' - ' + export_type,current_user, 1);
                _paq.push(['trackEvent', 'export_' + export_format, current_country + ' - ' + export_type, current_user]); // TODO currently not tracked on Matomo side

                // https://developers.google.com/analytics/devguides/collection/analyticsjs/user-timings#measuring_time
                start_time = timer_start();

                var task_id = null;  // background task identifier
                var interval_id = null;  // task status polling interval
                var export_url = null;  // export file URL
                var download = function(url) {
                    // Create an invisible <a> element with a 'download'
                    // attribute, then emulate a click on it to initiate the
                    // download
                    var a = document.createElement('a');
                    a.setAttribute('href', url);
                    var filename = url.split('/').pop();
                    a.setAttribute('download', filename);
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                };
                var poll_export_status = function() {
                    customAxios.get('data/export-status/' + task_id)
                    .then(function(response) {
                        return response.data;
                    })
                    .then(function(data) {
                        if (data.status_msg.startsWith('SUCCESS') || data.status_msg.startsWith('WARNING') || data.status_msg.startsWith('ERROR')) {
                            // Stop polling
                            window.clearInterval(interval_id);
                            // Stop timer
                            time_delta = timer_stop(start_time);
                            timer_send('export_' + export_format, current_country + ' - ' + export_type, time_delta, current_user);
                            // Notify completion
                            if (data.status_msg.startsWith('SUCCESS')) {
                                export_url = data.payload.url;
                                // Notify success to user with download action
                                iziToast.show({
                                    buttons: [
                                        [
                                            '<button>Download</button>',
                                            function(instance, toast) {
                                                download(data.payload.url);
                                                instance.hide({}, toast);
                                            },
                                            true,
                                        ],
                                    ],
                                    message: 'Your file is ready.',
                                    timeout: false,
                                    title: 'Export',
                                });
                            } else {
                                var failure = 'error';
                                var message = 'Your file could not be exported.';
                                if (data.status_msg.startsWith('WARNING')) {
                                    failure = 'warning';
                                    switch (data.status_msg.split(' - ')[1]) {
                                        case 'EMPTY':
                                            message = 'No point of interest to export.';
                                            break;
                                        case 'TOO_MANY_ROWS':
                                            message = 'Too many rows. Consider adding filters or limiting the area.';
                                            break;
                                    }
                                }
                                // Notify warning/error to user
                                iziToast.show({
                                    class: failure,
                                    message: message,
                                    title: 'Export',
                                });
                            };
                        };
                    })
                    .catch(error => {
                        console.error(error);
                    });
                };

                // TODO move SQL filters creation to backend
                customAxios.post("data/export",
                        {
                            carto_api_key : carto_api_key,
                            country: current_scenario['iso2'],
                            export_format : export_format,
                            bounding_box : wkt,
                            export_type: export_type,
                            source_table : source_table,
                            initial_filter: initial_filter,
                            filters : columns_filters,
                        })
                .then(function(response) {
                    return response.data;
                })
                .then(function(data) {
                    if (data.status_msg === 'PENDING') {
                        // Export is ongoing as background task
                        task_id = data.payload.task_id;
                        // Notify ongoing state to user
                        iziToast.show({
                            message: 'Your file is being prepared… You will be notified.',
                            title: 'Export',
                        });
                        // Periodically poll export status to report progress
                        interval_id = window.setInterval(poll_export_status, 1000);
                    } else {
                        throw new Error();  // go to the catch() section below
                    };
                })
                .catch((error) => {
                    // catch API call errors here
                    console.error(error);
                    // Notify error to user
                    iziToast.show({
                        class: 'error',
                        message: 'The operation could not be scheduled.',
                        title: 'Export',
                    });
                });
            } else {
                console.log('No source table provided for this export type in scenario');
            };

            return false;
        });
    });
};


// input = DOM element id of a layer "checkbox" (['G' + group_index + 'L' + layer_index])
function register_layer_toggle_callback(layer_input_id) {
      /* fire event when a layer is activated or deactivated */
        document.querySelector('#'+layer_input_id).addEventListener('change', function (event) {
        if (! event.target.checked) {
            // cannot enter here from a radio button, so we can skip the mutex code
                carto_layer_input_ids[layer_input_id].hide(); // faster than carto_client.removeLayer(carto_layer_input_ids[layer_input_id]);
        } else {
            // is layer group a radio group ?
            is_radio_group = false;
            layer_group_id = layer_input_id.split('L')[0];
            callback_layers_toggle_list.forEach(function (callback_layer, index) {
                if (callback_layer.split('L')[0]===layer_group_id) {
                    if (callback_layer.endsWith('-1')) { is_radio_group = true};
                };
            });
            // mutex mode for radio groups : hide all other layers
            if (is_radio_group) {
                // look for layers in same group and hide active one
                Object.keys(carto_layer_input_ids).forEach(function (other_layer_input_id, layer_index) {
                    // note : None layer is not in that list
                    if (other_layer_input_id.split('L')[0]===layer_input_id.split('L')[0]) {
                        carto_layer_input_ids[other_layer_input_id].hide();
                    };
                });
            };
            // and show self only if not the None layer
            if (layer_input_id.endsWith('-1')) {
                // console.log('None special case - removing the active layer in group');
            } else {
//                carto_client.addLayer(carto_layer_input_ids[layer_input_id]);
                // in case layer was not initially loaded
                if (carto_layer_input_ids[layer_input_id].isHidden()) {
                    carto_layer_input_ids[layer_input_id].show();
                } else {
                    //TODO make sure the layer is inserted at the appropriate index
                    carto_client.addLayer(carto_layer_input_ids[layer_input_id]);
                };
            };
        };
    });
};

// input = DOM element id of a layer "info" icon (['G' + group_index + 'L' + layer_index + '_i'])
function register_layer_info_callback(layer_info_id) {

    // set button as active only when layer has _featureClickColumns
    if (carto_layer_infos_ids[layer_info_id]['_featureClickColumns'].length) {

        document.querySelector('#'+layer_info_id).addEventListener('click', function (event) {
            //TODO in callback
            // - set class of all info buttons to regular
            // - set class of clicked info button to "bold" => done
            // - disable featureclick callbacks on all (other) layers
            // - enable featureclick callback on selected layer => done

            // block href call
            event.preventDefault();

            // toggle class for UI change
            $(this).toggleClass('active');
            // toggle event handler
            if ($(this).hasClass('active')) {
                setPopupsClick(layer_info_id);
            } else {
                unsetPopupsClick(layer_info_id);
            };
        });
    } else {
        // block href call
        event.preventDefault();
        $("#"+layer_info_id).addClass('hidden');
    };
};

function setPopupsClick(layer_info_id) {
    // retrieve carto layer registered with info button
    const carto_layer = carto_layer_infos_ids[layer_info_id];

//    console.log(carto_layer);

    carto_layer.off('featureOver');
    carto_layer.off('featureOut');
    carto_layer.on('featureClicked', openPopup);
};

function unsetPopupsClick(layer_info_id) {
    // retrieve carto layer registered with info button
    const carto_layer = carto_layer_infos_ids[layer_info_id];

    carto_layer.off('featureOver');
    carto_layer.off('featureOut');
    carto_layer.off('featureClicked');
};

function closePopup(featureEvent) {

    info_popup.removeFrom(map);
};

// this is where the info popup formatting happens
function openPopup(featureEvent) {
    // featureEvent.data = {cartodb_id:.., field-list }
    // featureEvent.latLng = {lat: ..., lng: ...}
    // featureEvent.position = {x: ..., y: ...}

    //TODO try to find a way to recover CARTO layer or layer info here so we can apply specific rules on content

    content = '';
//    content += '<h1 class="as-title">Info</h1>';
    content += '<p><span class="as-badge as-bg--badge-green">Information</span></p>';
    content += '<table class="as-table">';
//    content += '<thead><tr><th>Field</th><th>Value</th></tr></thead>';
    content += '<tbody>';

    //    content += '<tr><td>Product name</td><td>'+featureEvent.data.product_name.toUpperCase()+'</td></tr>'
    //    content += '<tr><td>Status</td><td>'+featureEvent.data.status.toUpperCase()+'</td></tr>'
    for (let [key, value] of Object.entries(featureEvent.data)) {
        // check if data is numeric or percentage

        number = Number(value);
        if (number || number === 0) {
            if (key.substring(0, 4) === 'pct_') {
                value_str = String(to_locale(number/100, 'percent', 0, 2));
            } else if (key.substring(key.length - 6, key.length) === '_ratio') {
                value_str = String(to_locale(number, 'percent', 0, 2));
            } else if (key.substring(key.length - 3, key.length) === '_id') {
                value_str = String(value);
            } else if (key === 'postcode') {
                value_str = String(value);
            } else {
                value_str = String(to_locale(value, 'decimal', 0, 2));
            };
        } else {
                value_str = value;
        };

        if (key==='cartodb_id') {
            // we do not want to display the DB unique ID, so we skip this (key, value) here
        }
        else {
            content += '<tr><td>'+field_rename(key)+'</td><td>'+value_str+'</td></tr>';
        };
    };
    content += '</tbody></table>';

    info_popup.setContent(content);
    info_popup.setLatLng(featureEvent.latLng);
    if (!info_popup.isOpen()) {
          info_popup.openOn(map);
    }
};

function register_group_eye_callback(group_eye_id) {

    // when eye is switched off:
    // - hide all group layers from map (at least the ones that were displayed)
    // - disable radio / checkboxes in group to avoid inconsistency
    // - keep widgets visible ? yes for now
    // when on:
    // - show the 'on' layers back
    // - enable radio / checkboxes back

    document.querySelector('#'+group_eye_id).addEventListener('click', function (event) {

        event.preventDefault();
        // toggle class
        $(event.target).toggleClass('hide');
        // and check where we are
        hide = $(event.target).hasClass('hide');
        // recover group number so we can iterate over layer controls in the group controlled by this button
        group_index = group_eye_id.split('_')[2];
        // try to disable 'None' radio button if group is radio
        layer_index = -1;
        layer_input = $('#' + 'G' + group_index + 'L' + layer_index);
        if (layer_input.length) {
            layer_input = layer_input[0];
            layer_input_status = layer_input.checked;
            if (hide) { layer_input.disabled = false; } else { layer_input.disabled = true; };
        };
        // now loop on regular layers
        groups_layers_list[group_eye_id].forEach(function (layer, layer_index) {
            layer_input = $('#' + 'G' + group_index + 'L' + layer_index)[0];
            layer_input_status = layer_input.checked;
            // - check if call was for an eye switched on or off
            if (hide) {
                    // no need to check if input control is checked, it will help recover if problems occur
                    layer.hide();
                // disable input so there is no inconsistency in layer visibility
                layer_input.disabled = true;
            } else {
                if (layer_input_status) {
                    layer.show();
                };
                // enable input so there is no inconsistency in layer visibility
                layer_input.disabled = false;
            };
        });
    });
};

function populate_widget_box() {
      // we  need to wait for API promise to be fulfilled, so we register this as a callback
      // we can also call it on dropdown change

      // get layers data that were returned by the API
      const layer_groups = current_scenario['layer_groups'];

      // drop previous layer box content
      document.getElementById('widgets_box').innerHTML = '';

      if (layer_groups.length) {
          layer_groups.forEach(function (layer_group, index) {
              add_widget_group(index);
          });
      };
      // dynamic callback registration needs to happen when carto layers are available (Promise)
      // call done in carto stuff
};

function add_widget_group(group_index) {

    var layer_group = current_scenario['layer_groups'][group_index];

    // add group only if it contains layers
    if (layer_group.length) {

        // loop over list of layers
        layer_group.forEach(function (layer, layer_index) {

            // add a widget group for layer only if layer has widgets defined

            // console.warn('add_widget_group', {widgets: layer.widgets})

            if (layer.widgets.length) {
                widgets_box = document.getElementById('widgets_box');

                var widget_group = L.DomUtil.create('div');
                widget_group.id = "layer_" + layer_index + "_widgets";

                var widget_group_title = L.DomUtil.create('p', 'as-subheader as-widget-group');
                widget_group_title.id = layer.displayed_name + '_titl';
                widget_group_title.innerHTML = layer.displayed_name;

                widget_group.appendChild(widget_group_title);

                var layer_div = L.DomUtil.create('div', "as-body");
                layer_div.id = layer.displayed_name + '_ldiv';

                // if div is not attached to DOM, we cannot add widgets to it
                widget_group.appendChild(layer_div);
                widgets_box.appendChild(widget_group);

                // recover layer_div DOM element id after insertion
                layer_div = document.getElementById(layer.displayed_name + '_ldiv');

                // Get list of widgets defined for layer
                var widgets_list = layer.widgets;

                widgets_list.forEach(function (widget, widget_index) {
                    if (widget['widget_type']==='formula') {
                        console.log('Formula widget');
// TODO add support for formula widget
                    } else if (widget['widget_type']==='category') {
                        var widget_element = L.DomUtil.create('as-category-widget');
                        widget_element.id = group_index + '_' + layer_index + '_' + widget_index + '_wdgt';
                        widget_element.setAttribute("style", "display:table"); // TODO move to CSS
                        widget_element.setAttribute("heading", widget['heading']);
                        widget_element.setAttribute("description", widget['description']);
                        widget_element.setAttribute("show-clear-button", true);
                        layer_div.appendChild(widget_element);
                        var widget_apply_element = L.DomUtil.create('span', 'as-badge as-bg--primary as-color--type-04');
                        widget_apply_element.id = group_index + '_' + layer_index + '_' + widget_index + '_aply';
                        widget_apply_element.setAttribute("style", "display:none");
                        widget_apply_element.innerHTML = "Apply";
                        layer_div.appendChild(widget_apply_element);
                        carto_load_category_widget(widget_element.id, widget_apply_element.id, layer.source,
                                            widget['widget_params']['column'],
                                            widget['widget_params']['operation'],
                                            widget['widget_params']['limit'],
                                            widget['widget_params']['operation_column']);
                    } else if (widget['widget_type']==='histogram') {
                        var widget_element = L.DomUtil.create('as-histogram-widget');
                        widget_element.setAttribute("color", "#C0EDFE"); // --Konnect_Global_Light_Blue_20
                        widget_element.id = group_index + '_' + layer_index + '_' + widget_index + '_wdgt';
                        widget_element.setAttribute("style", "display:table"); // TODO move to CSS
                        widget_element.setAttribute("heading", widget['heading']);
                        widget_element.setAttribute("description", widget['description']);
                        if (widget['xlabel']) {
                            widget_element.setAttribute('x-label', widget['xlabel']);
                        };
                        if (widget['ylabel']) {
                            widget_element.setAttribute('y-label', widget['ylabel']);
                        };
                        widget_element.setAttribute("show-clear", true);
                        layer_div.appendChild(widget_element);
                        carto_load_histogram_widget(widget_element.id, layer.source,
                                            widget['widget_params']['column'],
                                            widget['widget_params']['bins'],
                                            widget['widget_params']['start'],
                                            widget['widget_params']['end']);
                    } else if (widget['widget_type']==='histocat') {
                        var widget_element = L.DomUtil.create('as-histogram-widget');
                        widget_element.setAttribute("color", "#C0EDFE"); // --Konnect_Global_Light_Blue_20
                        widget_element.id = group_index + '_' + layer_index + '_' + widget_index + '_wdgt';
                        widget_element.setAttribute("style", "display:table"); // TODO move to CSS
                        widget_element.setAttribute("heading", widget['heading']);
                        widget_element.setAttribute("description", widget['description']);
                        if (widget['xlabel']) {
                            widget_element.setAttribute('x-label', widget['xlabel']);
                        };
                        if (widget['ylabel']) {
                            widget_element.setAttribute('y-label', widget['ylabel']);
                        };
                        widget_element.setAttribute("show-clear", true);
                        layer_div.appendChild(widget_element);
                        carto_load_histogram_categorical_widget(widget_element.id, layer.source,
                                            widget['widget_params']['column'],
                                            widget['widget_params']['limit']);
                    } else if (widget['widget_type']==='rangeslider') {
                        var widget_element = L.DomUtil.create('as-range-slider');
                        widget_element.setAttribute("color", "#C0EDFE"); // --Konnect_Global_Light_Blue_20
                        widget_element.id = group_index + '_' + layer_index + '_' + widget_index + '_wdgt';
                        var widget_header_element = L.DomUtil.create('h2', 'as-widget-header__header');
                        widget_header_element.innerHTML = widget['heading'];
                        layer_div.appendChild(widget_header_element);
                        var widget_description_element = L.DomUtil.create('p', 'as-widget-header__subheader as-body');
                        widget_description_element.innerHTML = widget['description'];
                        layer_div.appendChild(widget_description_element);
                        unit = widget['widget_params']['unit'];
                        widget_element.formatValue = (value) => (to_locale(value)); // TODO use unit here to properly format numbers
                        widget_element.setAttribute("draggable", true);
                        layer_div.appendChild(widget_element);
                        carto_load_rangeslider_widget(widget_element.id, layer.source,
                                            widget['widget_params']['column']);
                    } else if (widget['widget_type']==='timeseries') {
                        var widget_element = L.DomUtil.create('as-time-series-widget');
                        widget_element.setAttribute("color", "#C0EDFE"); // --Konnect_Global_Light_Blue_20
                        widget_element.setAttribute("style", "display:table"); // TODO move to CSS
                        widget_element.id = group_index + '_' + layer_index + '_' + widget_index + '_wdgt';
                        widget_element.setAttribute("heading", widget['heading']);
                        if (widget['xlabel']) {
                            widget_element.setAttribute('x-label', widget['xlabel']);
                        };
                        if (widget['ylabel']) {
                            widget_element.setAttribute('y-label', widget['ylabel']);
                        };
                        widget_element.setAttribute("show-clear", true);
                        layer_div.appendChild(widget_element);
                        carto_load_timeseries_widget(widget_element.id, layer.source,
                                            widget['widget_params']['column'],
                                            widget['widget_params']['aggregation'],
                                            widget['widget_params']['date_format']);
                    } else {
                        console.log('Unknown widget_type:', widget['widget_type']);
                    };
                });

            };
        });
    };

};

function move_coverage_layers(on_top) {

    var layers_count = 0;
    var top_layer_index = 0;    // index where we will insert all network layers one after the other
    var bottom_layer_index = 0; // index where we will push all network layers back in place
    var start_counting_other_layers = false; // flag to count bottom group layers only (below coverage)

    for (const [group_eye_id, group_layers] of Object.entries(groups_layers_list)) {
        layers_count = layers_count + group_layers.length;

        // this needs to be done before the next block to avoid counting the network layers
        if (start_counting_other_layers) {
            bottom_layer_index = bottom_layer_index + group_layers.length;
        };

        if (group_layers === coverage_layers_list) {
            start_counting_other_layers = true;
        };
    };

    top_layer_index = layers_count-1;

    // we need the list in reverse order for on_top to simplify the forEach loop
    layers_list = coverage_layers_list;
    layers_list.reverse();

    layers_list.forEach(function (layer, layer_index) {
        if (on_top) {
            layer.setOrder(top_layer_index);
        } else {
            layer.setOrder(bottom_layer_index);
        };
    });
};

function switch_coverage_planned(planned_switch_status) {

    // iterate over all scenario layers, so we can apply the status column filter on tables other than coverage too
    const layer_groups = current_scenario['layer_groups'];
    let carto_source;

    // loop on each layer group
    if (layer_groups.length) { // handle situation where no group defined in JSON
        layer_groups.forEach(function (layer_group, group_index) {
            if (layer_group.length) { // handle empty group case
                layer_group.forEach(function (layer_json, layer_index) {
                    if (layer_json['has_planned']) {
                        carto_source = carto_sources[layer_json['source']];
                        if (planned_switch_status) {
                            carto_source.removeFilter(planned_coverage_filter); // show planned coverage
                        } else {
                            carto_source.addFilter(planned_coverage_filter); // hide planned coverage
                        };
                    };
                });
            };
        });
    };
};

////////////////////////////
/* Layer legends handling */
////////////////////////////

// input = DOM element id of a layer title (['G' + group_index + 'L' + layer_index + '_l'])
function register_layer_legend_callback(layer_label_id) {

    // recover scenario data for this layer
    group_index = Number(layer_label_id.split('L')[0].split('G')[1]);
    layer_index = Number(layer_label_id.split('_')[0].split('L')[1]);
    layer_group = current_scenario['layer_groups'][group_index];
    layer_content = layer_group[layer_index];

    /* fire event when mouse hovers in or out over a layer title only when layer has legend = True in scenario */
    if (layer_content['legend']) {

        layer_label = document.querySelector('#'+layer_label_id);
        layer_label.addEventListener('mouseover', function (event) {
            setLegendContent(this);
        });
        layer_label.addEventListener('mouseout', function (event) {
            hide_legend();
        });
    };
};

// called on layer title mouseover to open and populate the legend box
function setLegendContent(event) {
    layer_label_id = event.id;

    // recover scenario data for this layer
    group_index = Number(layer_label_id.split('L')[0].split('G')[1]);
    layer_index = Number(layer_label_id.split('_')[0].split('L')[1]);
    layer_group = current_scenario['layer_groups'][group_index];
    layer_content = layer_group[layer_index];

    // retrieve carto layer registered with layer label
    const carto_layer = carto_layer_label_ids[layer_label_id];

    // build each part of the legend box individually
    layer_title_component = `<span class="as-badge as-bg--badge-blue">Layer name</span><div id="legend_label"><label class="as-caption">${layer_content['displayed_name']}</label></div>`;

    // this part is computed when layer is loaded with the 'metadataChanged' event, but only if layer uses TurboCARTO
    if (!carto_layer_style_ids[layer_label_id]) {
        // style not defined, so this must be a static style which did not fire the 'metadataChanged' event, so lets define it now
        parsed = carto_layer.getStyle()._content.split('#layer {')[1].split('}')[0];
        parsed_attrs = parsed.split(';');
        parsed_attrs.forEach(function(attr) {
            if (attr.length) {
                attr_cleaned = attr.trim();
            } else {
                attr_cleaned = '';
            };
            if (attr_cleaned.length) {
                attr_key = attr_cleaned.split(':')[0].trim();
                attr_value = attr_cleaned.split(':')[1].trim();
            } else {
                attr_key = '';
                attr_value = '';
            };
            if (attr_key === 'marker-fill' || attr_key === 'polygon-fill') {
                const common_color = attr_value;
            } else {
                const common_color = '#ffffff';
            };
        });
        html_code = `<span id="legend_column_name" class="as-badge as-bg--badge-green">Features</span><div id="legend_style">`;
        html_code = `${html_code}<ul class="category">`;
        html_code = `${html_code}<li><div class="circle" style="background:${common_color}"></div><span class="as-legend-item as-body">${layer_content['displayed_name']}</span></li>`;
        html_code = `${html_code}</ul>`;
        html_code = `${html_code}</div>`;
        carto_layer_style_ids[layer_label_id] = html_code;
    };
    // now style is defined, we can proceed
    layer_legend_component = `${carto_layer_style_ids[layer_label_id]}`;

    if (layer_content['initial_filter']) {
        layer_filter_content = `${layer_content['initial_filter']}`;
    } else {
        layer_filter_content = 'None';
    };
    layer_filter_component = `<span class="as-badge as-bg--badge-gray">Filter set on source</span><div id="legend_filter" class="as-body as-color--type-03">${layer_filter_content}</div>`;

    // put everything together to insert HTML code inside legend box
    legend_content = `${layer_title_component}${layer_legend_component}${layer_filter_component}`;
    show_legend(legend_content);
};

// called on layer 'metadataChanged', and one of the styleMetadata property is 'polygon-fill'
function renderLegendPolygon(styleMetadata, carto_layer_id) {

    legend_type = styleMetadata.getType();
    var column_name_pretty = field_rename(styleMetadata['_column']);

    html_code = `<span id="legend_column_name" class="as-badge as-bg--badge-green">Features</span><div id="legend_style">`;
    html_code = `${html_code}<div class="as-body">${column_name_pretty}</div><ul class="category">`;

    if (legend_type === 'categories') {
        const categories = styleMetadata.getCategories();
        for (category of categories) {
            html_code = `${html_code}<li><div class="circle" style="background:${category.value}"></div><span class="as-legend-item as-body">${category.name}</span></li>`;
        };
    } else if (legend_type === 'buckets') {
        const buckets = styleMetadata.getBuckets();
        for (bucket of buckets) {
            html_code = `${html_code}<li><div class="circle" style="background:${bucket.value}"></div><span class="as-legend-item as-body">[${to_locale(bucket.min)} - ${to_locale(bucket.max)}]</span></li>`;
        };
    } else {
        console.log('styleMetadata style not in [categories, buckets]');
    };

    html_code = `${html_code}</ul>`;
    html_code = `${html_code}</div>`;
    carto_layer_style_ids[carto_label_id] = html_code;
};

// called on layer 'metadataChanged', and one of the styleMetadata property is 'marker-fill'
function renderLegendPoint(styleMetadata,carto_layer_id) {

    legend_type = styleMetadata.getType();
    var column_name_pretty = field_rename(styleMetadata['_column']);

    html_code = `<span id="legend_column_name" class="as-badge as-bg--badge-green">Features</span><div id="legend_style">`;
    html_code = `${html_code}<div class="as-body">${column_name_pretty}</div><ul class="category">`;

    if (legend_type === 'categories') {
        const categories = styleMetadata.getCategories();
        for (category of categories) {
            html_code = `${html_code}<li><div class="circle" style="background:${category.value}"></div><span class="as-legend-item as-body">${category.name}</span></li>`;
        };
    } else if (legend_type === 'buckets') {
        const buckets = styleMetadata.getBuckets();
        for (bucket of buckets) {
            html_code = `${html_code}<li><div class="circle" style="background:${bucket.value}"></div><span class="as-legend-item as-body">[${to_locale(bucket.min)} - ${to_locale(bucket.max)}]</span></li>`;
        };
    } else {
        console.log('styleMetadata style not in [categories, buckets]');
    };

    html_code = `${html_code}</ul>`;
    html_code = `${html_code}</div>`;
    carto_layer_style_ids[carto_layer_id] = html_code;
};

function show_legend(content) {
    document.getElementById('info-panel-box').innerHTML = content;
    $('#map-info-panel div').addClass("visible").addClass("legend");
};

function hide_legend() {
    document.getElementById('info-panel-box').innerHTML = '';
    $('#map-info-panel div').removeClass("visible");
};

export { current_scenario, scenarios_data, nav_init, nav_start, load_user_scenarios, 
    add_key_stat, remove_key_stat, 
    renderLegendPolygon, renderLegendPoint, 
    register_dynamic_callbacks,
    tools_info_click, tools_distance_click, tools_measure_click, tools_scoring_click, tools_draw_click,
    tools_isoline_distance_click, tools_isoline_time_click, tools_draw_standard_tools_click };