import iziToast from 'izitoast'
import axios from 'axios'

function toast_init() {
    iziToast.settings({
        animateInside: false,
        position: 'topCenter',
        progressBar: false,
        timeout: 4000,
        transitionIn: 'fadeInDown',
        transitionOut: 'fadeOutUp',
    });
};

function getPage() {
    const pathname = window.location.pathname
    if (pathname.startsWith('/login')) {
        return 'login'
    }
    if (pathname === '/') {
        return 'home'
    }
    if (pathname.startsWith('/map/')) {
        return 'map'
    }
};

// get token from page meta
var csrf_token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

var customAxios = axios.create({
    baseURL: '/api/1/',
    timeout: 30000,
    headers: {'X-Custom-Header': 'axios'}
});

customAxios.defaults.headers.post['X-CSRF-TOKEN'] = csrf_token;
customAxios.defaults.headers.put['X-CSRF-TOKEN'] = csrf_token;
customAxios.defaults.headers.delete['X-CSRF-TOKEN'] = csrf_token;
customAxios.defaults.headers.patch['X-CSRF-TOKEN'] = csrf_token;
// Axios does not create an object for TRACE method by default, and has to be created manually.
customAxios.defaults.headers.trace = {}
customAxios.defaults.headers.trace['X-CSRF-TOKEN'] = csrf_token;


var locale_region = "en-GB"; // TODO add to user profile ?

// D3 locale from https://unpkg.com/d3-time-format@2.2.2/locale/en-GB.json
var en_GB = {
  "dateTime": "%a %e %b %X %Y",
  "date": "%d/%m/%Y",
  "time": "%H:%M:%S",
  "periods": ["AM", "PM"],
  "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  "shortMonths": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
function to_locale(number, style, param1, param2) {

    // style in [decimal, currency, percent, unit]
    if (!style || style === 'decimal') {
        if (param1 === null) { param1 = 0};
        if (param2 === null) { param2 = 2};
        return new Intl.NumberFormat(locale_region, { style: style, minimumFractionDigits: param1, maximumFractionDigits: param2 }).format(number);
    } else if (style === 'currency') {
        if (!param1) { param1 = 'EUR'};
        if (param2 === null) { param2 = 0};
        return new Intl.NumberFormat(locale_region, { style: style, currency: param1, minimumFractionDigits: param2, currencyDisplay: 'symbol' }).format(number);
    } else if (style === 'percent') {
        if (param1 === null) { param1 = 0};
        if (param2 === null) { param2 = 2};
        return new Intl.NumberFormat(locale_region, { style: style, minimumFractionDigits: param1, maximumFractionDigits: param2 }).format(number);
    } else if (style === 'unit') {
        if (!param1) { param1 = 'kilometer'};
        if (!param2) { param2 = 'short'};

        // check if 'unit' is available (Chrome vs Firefox or Safari, see https://stackoverflow.com/questions/60566942/why-doesnt-intl-numberformat-work-with-units-in-safari-and-firefox)
        function getIntlNumberFormatWithUnit(unit) {
            try {
                return new Intl.NumberFormat(undefined, {
                style: "unit",
                unit
                });
            } catch (e) {
                if (e.constructor !== RangeError) {
                throw e;
            }
            return null;
            }
        };
        supported = getIntlNumberFormatWithUnit(param1);

        if (supported) {
            return new Intl.NumberFormat(locale_region, { style: style, unit: param1, unitDisplay: param2  }).format(number);
        } else {
            switch (param1) {
                case 'kilometer':
                    param1 = 'km';
                    break;
                case 'meter':
                    param1 = 'm';
                    break;
                case 'percent':
                    param1 = '%';
                    break;
                case 'second':
                    param1 = 's';
                    break;
            };
            return new Intl.NumberFormat(locale_region, { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0  }).format(number) + ` ${param1}`;
        };



    } else {
        console.log('Error in to_locale() params');
        return number.toLocaleString(locale_region);
    };
};

////////////////////////////////////////
/* Google Analytics utility functions */
////////////////////////////////////////

// https://developers.google.com/analytics/devguides/collection/analyticsjs/user-timings#measuring_time
function timer_start() {

    let timeSincePageLoad;

    // Feature detects Navigation Timing API support.
    if (window.performance) {
      // Gets the number of milliseconds since page load
      // (and rounds the result since the value must be an integer).
      timeSincePageLoad = Math.round(performance.now());
      return timeSincePageLoad;
        } else {
            console.log('Navigation Timing API not supported');
            return -1
        };
};

function timer_stop(start_time) {

    let timeSincePageLoad;
    let time_delta;

    if (window.performance) {
      // Gets the number of milliseconds since page load
      // (and rounds the result since the value must be an integer).
      timeSincePageLoad = Math.round(performance.now());
      time_delta = timeSincePageLoad - start_time;
      return time_delta;
        } else { return -1 }
};

function timer_send(timing_category, timing_var, time_delta, timing_label) {
    if (time_delta > 0) {
      // ga('send', 'timing', timing_category, timing_var, time_delta, timing_label);
    };
};

function handleApiError(error) {
    console.error(error.message, {error});
    // Logout by emulating a click on the logout link
    if (error.response && error.response.status === 401) {
        document.querySelector('.as-toolbar__login .logout').click();
    };
};


export { timer_start, timer_stop, timer_send, 
    handleApiError, toast_init, to_locale, getPage,
    customAxios };