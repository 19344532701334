import 'leaflet-control-geocoder'
import 'leaflet-hash'
import 'leaflet-draw'
import Wkt from 'wicket'
import 'leaflet.fullscreen'
import 'leaflet.measurecontrol'
import 'leaflet-mouse-position'

// From map.html (would benefit from dynamic import)
import L from 'leaflet'

// Fix Leaflet default icons loading
// https://github.com/Leaflet/Leaflet/issues/4968#issuecomment-319569682
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png'
import iconUrl from 'leaflet/dist/images/marker-icon.png'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'
L.Marker.prototype.options.icon = L.icon({
    iconRetinaUrl, iconUrl, shadowUrl,
    iconSize: [25, 41], shadowSize: [41, 41],
    iconAnchor: [12, 41], popupAnchor: [1, -34], tooltipAnchor: [16, -28],
})

import * as turf from '@turf/turf'

import { current_scenario, 
    tools_info_click, tools_distance_click, 
    tools_measure_click, tools_scoring_click, 
    tools_isoline_distance_click, tools_isoline_time_click, 
    tools_draw_click, tools_draw_standard_tools_click } from './map_nav.js';

import { carto_update_views_filter_mode, circleFilter, polygonFilter, cartoLayer } from './map_carto.js';

// Implicit 'non-strict' global variables made explicit
var lat;
var lng;
var zlv;
var draw_isodistance_span;
var element;
var draw_isotime_span;
var c;
var isolineLayerDebug;

// Legacy global variables
let map;
var draw_control; // Leaflet Draw control
var drawn_items; // Leaflet Draw polygon layer used for filters
var info_popup;
var isolineLayer;
var wicket = new Wkt.Wkt(); // Create a new Wicket instance for converting between Leaflet objects, GeoJSON and WKT

var polygonData;

function map_init() {

        /////////////////////////
        /* Leaflet map section */
        /////////////////////////

        var basemapVoyagerNolabels = L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}.png', {
            maxZoom: 18,
            zIndex: -10

        });
        var basemapVoyager = L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png', {
            maxZoom: 18,
            zIndex: -10

        });
        var CartoDB_Positron = L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="//www.openstreetmap.org/copyright">OpenStreetMap contributors</a>, &copy; <a href="//carto.com/attributions">CARTO</a>',
            subdomains: 'abcd',
            zIndex: -10,
            maxZoom: 19
        });
        var CartoDB_DarkMatter = L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="//www.openstreetmap.org/copyright">OpenStreetMap contributors</a>, &copy; <a href="//carto.com/attributions">CARTO</a>',
            subdomains: 'abcd',
            zIndex: -10,
            maxZoom: 19
        });
        var basemap0 = L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="//openstreetmap.org">OpenStreetMap</a> contributors,<a href="//creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
            maxZoom: 18
        });
        var basemap1 = L.tileLayer('//mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', {
                attribution: 'Map data &copy; <a href="//www.google.com/permissions/geoguidelines/attr-guide.html">Google</a>',
                maxZoom: 20
        });
        var basemap2 = L.tileLayer('//tile-{s}.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: 'Map data &copy; <a href="h//openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        });
        var Google_terrain = L.tileLayer('//mt1.google.com/vt/lyrs=p&x={x}&y={y}&z={z}', {
            attribution: 'Map data &copy; <a href="//www.google.com/permissions/geoguidelines/attr-guide.html">Google</a>',
            subdomains: 'abcd',
            maxZoom: 20
        });
        var Google_maps = L.tileLayer('//mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
            attribution: 'Map data &copy; <a href="//www.google.com/permissions/geoguidelines/attr-guide.html">Google</a>',
            subdomains: 'abcd',
            maxZoom: 20
        });
        var Esri_WorldImagery = L.tileLayer('//server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
            attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        });

        /* Mapbox tiles properly licensed with  API key  */

        var token ="pk.eyJ1IjoibWFzYWUiLCJhIjoiY2w1YXVnYXR0MDFiNTNibXM2enR1aGF0MiJ9.268qAH7oilHyeEj2dkACnw";

        /* raster tiles */
        var basemapMapboxEmerald = L.tileLayer('//api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
            id: 'mapbox/outdoors-v11',
            attribution: '<a href="https://www.mapbox.com/about/maps/" target="_blank">© Mapbox</a> <a href="http://www.openstreetmap.org/about/" target="_blank">© OpenStreetMap</a>',
            tileSize: 512,
            maxZoom: 19,
            zoomOffset: -1,
            accessToken: token
        });
        var mapbox_streets = L.tileLayer('//api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
                id: 'mapbox/streets-v11',
                attribution: '<a href="https://www.mapbox.com/about/maps/" target="_blank">© Mapbox</a> <a href="http://www.openstreetmap.org/about/" target="_blank">© OpenStreetMap</a>',
                tileSize: 512,
                maxZoom: 19,
                zoomOffset: -1,
                accessToken: token
        });
        var mapbox_satellite_streets = L.tileLayer('//api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
                id: 'mapbox/satellite-streets-v11',
                attribution: '<a href="https://www.mapbox.com/about/maps/" target="_blank">© Mapbox</a> <a href="http://www.openstreetmap.org/about/" target="_blank">© OpenStreetMap</a> <a href="https://www.maxar.com/" target="_blank">© Maxar</a>',
                tileSize: 512,
                maxZoom: 19,
                zoomOffset: -1,
                accessToken: token
        });


      lat = current_scenario['map_config']['center_lat'];
      lng = current_scenario['map_config']['center_lng'];
      zlv = current_scenario['map_config']['zoom_init'];

      map = L.map('map', {
          minZoom: 5,
          maxZoom: 20,
          zoomControl: false,               // initially disabled so we can position it @ bottomright
          layers: [CartoDB_Positron]
      }).setView(new L.LatLng(lat, lng), zlv);

      var hash = new L.Hash(map);

      var baseMaps = {
          "CARTO Positron": CartoDB_Positron,
          "CARTO Voyager": basemapVoyager,
          "CARTO Dark": CartoDB_DarkMatter,
          "Mapbox Outdoors": basemapMapboxEmerald,
          "Mapbox Satellite": mapbox_satellite_streets,
          "Mapbox Streets": mapbox_streets,
      };

      info_popup = L.popup({ closeButton: true });
/*
      clcUrban = L..geoJSON(clcUrbanJSON, ...) # define clcUrbanJSON from AJAX request
      clcIndus = L..geoJSON(clcIndusJSON, ...) # define clcIndusJSON from AJAX request
      var overlayMaps = {
            "Land use" : {
                "Urban fabric": clcUrban,
                "Industrial and commercial": clcIndus
                }
        };
      L.control.layers(baseMaps, overlayMaps).addTo(map);
*/

      L.control.layers(baseMaps).addTo(map);
      map.on('baselayerchange', function() {
          //console.log('layer switched', e.layer);
            // reposition CARTO layer over basemap
            cartoLayer.setZIndex(10);
      });

    L.control.zoom({
        position: 'bottomright'

    }).addTo(map);

    L.control.mousePosition({
        position: 'bottomleft',
        separator: ', ',
        emptyString: 'Mouse coords',
        lngFirst: true,
        numDigits: 4,
        prefix: 'Lng, Lat: '
    }).addTo(map);

    // Leaflet fullscreen after map instanciation so we can position @ bottomright after zoom
    // fullscreenElement makes the whole SPA full screen, not just the map
    L.control.fullscreen({
        position: 'bottomright', // change the position of the button can be topleft, topright, bottomright or bottomleft, defaut topleft
        title: 'Enter full screen mode', // change the title of the button, default Full Screen
        titleCancel: 'Exit fullscreen mode', // change the title of the button when fullscreen is on, default Exit Full Screen
        content: null, // change the content of the button, can be HTML, default null
        forceSeparateButton: true, // force seperate button to detach from zoom buttons, default false
        forcePseudoFullscreen: false, // force use of pseudo full screen even if full screen API is available, default false
        fullscreenElement: $(".as-app")[0] // Dom element to render in full screen, false by default, fallback to map._container
    }).addTo(map);

    // Leaflet Geocoder
        var polySearch = L.polygon([[0, 0.05],[0, 0.05],[0, 0.05],[0, 0.05]]);

        var searchMarker;

        var reg = /(\s*[-+]?\s*[1]?\d{1,2})(?:\.\d+)?/g;
        var coord_search_mode = false;
        var search_lng = null;
        var search_lat = null;

    L.Control.geocoder({
            position: 'topleft',
            placeholder: 'Place search...',
            defaultMarkGeocode: false,
            collapsed: true,
            showResultsIcons: true,
            expand : "click"
            }).on('finishgeocode', function(e) {
                    // get the string that was sent to the geocoder, and try to extract coordinates from it
                    // if it succeeds, override results from (reverse) geocoding to avoid misplacing the marker
                    var input = e.input;
                    var results = input.match(reg);
                    if (results && results.length===2) {
                        // found exactly to floats matching regex
                        search_lng = parseFloat(results[0].trim());
                        search_lat = parseFloat(results[1].trim());
                        coord_search_mode = true;
                    }
                    else
                    {
                        search_lng = null;
                        search_lat = null;
                        coord_search_mode = false
                    }
                    // console.log(search_lng, search_lat);
            }).on('markgeocode', function(e) {

                    // Remove previous marker if there was any
                    if (searchMarker) {
                        map.removeLayer(searchMarker);
                    }

                    if (coord_search_mode) {
                        map.setView(new L.LatLng(search_lng, search_lat), zoomForCoordSearch);
                        searchMarker = L.marker([search_lng, search_lat]).addTo(map);
                    }
                    else {
                        // Zoom the map
                        var bbox = e.geocode.bbox;
                        polySearch.remove();
                        polySearch = L.polygon([
                             bbox.getSouthEast(),
                             bbox.getNorthEast(),
                             bbox.getNorthWest(),
                             bbox.getSouthWest()
                        ])
                        polySearch.addTo(map);
                        map.fitBounds(polySearch.getBounds());
                        polySearch.on('click', polySearch.remove());

                        // Add new marker
                        c = e.geocode.center;
                        searchMarker = L.marker([c['lat'], c['lng']]).addTo(map);
                        }
            }

            ).addTo(map);

    // BOTTOM LEFT CONTROLS, FROM BOTTOM TO TOP
        // see https://leafletjs.com/examples/extending/extending-3-controls.html for custom controls

        // controls the Leaflet Draw toolbar presence
        L.Control.CustomEdit = L.Control.extend({
            onAdd: function(map) {
                var img = L.DomUtil.create('img', 'btdrawing');

                img.src = '/static/img/leaflet_control_edit.png';
                img.style.width = '40px';

                img.setAttribute('data-toggle', 'tooltip');
                img.setAttribute('data-placement', 'right');
                img.setAttribute('title', 'Filter data by drawing polygons on the map');

                return img;
            },

            onRemove: function(map) {
                // Nothing to do here
            }
        });

        L.control.customedit = function(opts) {
            return new L.Control.CustomEdit(opts);
        }

        L.control.customedit({ position: 'bottomleft' }).addTo(map);

        // controls the scoring rule radio button group presence
        L.Control.CustomScoring = L.Control.extend({
            onAdd: function(map) {
                var img = L.DomUtil.create('img', 'btscoring');

                img.src = '/static/img/leaflet_control_scoring.png';
                img.style.width = '40px';

                img.setAttribute('data-toggle', 'tooltip');
                img.setAttribute('data-placement', 'right');
                img.setAttribute('title', 'Select rule and click on the map to get a score');

                return img;
            },

            onRemove: function(map) {
                // Nothing to do here
            }
        });

        L.control.customscoring = function(opts) {
            return new L.Control.CustomScoring(opts);
        }

        L.control.customscoring({ position: 'bottomleft' }).addTo(map);

        // Leaflet measurecontrol
        let measureControl = L.Control.measureControl({
            position: 'bottomleft'
        }).addTo(map);
        $('a.leaflet-control-draw-measure')[0].setAttribute('title', 'Click on the map to start measuring distances');

        // distance to broadband tool
        L.Control.CustomBBDist = L.Control.extend({
            onAdd: function(map) {
                var img = L.DomUtil.create('img', 'btbbdist');

                img.src = '/static/img/leaflet_control_radio.png';
                img.style.width = '40px';

                img.setAttribute('data-toggle', 'tooltip');
                img.setAttribute('data-placement', 'right');
                img.setAttribute('title', 'Click on the map to get distance to broadband');

                return img;
            },

            onRemove: function(map) {
                // Nothing to do here
            }
        });

        L.control.bbdist = function(opts) {
            return new L.Control.CustomBBDist(opts);
        }

        L.control.bbdist({ position: 'bottomleft' }).addTo(map);

        // info panel tool
        L.Control.CustomInfo = L.Control.extend({
            onAdd: function(map) {
                var img = L.DomUtil.create('img', 'btinfo');

                img.src = '/static/img/leaflet_control_info.png';
                img.style.width = '40px';

                img.setAttribute('data-toggle', 'tooltip');
                img.setAttribute('data-placement', 'right');
                img.setAttribute('title', 'Click on the map to get more info');

                return img;
            },

            onRemove: function(map) {
                // Nothing to do here
            }
        });

        L.control.custominfo = function(opts) {
            return new L.Control.CustomInfo(opts);
        }

        L.control.custominfo({ position: 'bottomleft' }).addTo(map);

    // Layer to draw circles/rectangles/polygons, used by Leaflet draw toolbar tools
    drawn_items = L.featureGroup().addTo(map);

    // Leaflet draw toolbar, controlled by L.control.customedit control
    L.drawLocal.draw.toolbar.buttons.polygon = 'Select data by defining a polygon';
    L.drawLocal.draw.toolbar.buttons.rectangle = 'Select data by defining a rectangle';
    L.drawLocal.draw.toolbar.buttons.circle = 'Select data by defining a circle';
    draw_control = new L.Control.Draw({
        position: 'bottomleft',
        draw: {
            polyline: false,
            polygon: {
                allowIntersection: false,
                showArea: true,
                metric: ['km'],
                drawError: {
                    color: '#b00b00',
                    timeout: 1000
                },
                shapeOptions: {
                    color: '#00A1FF'
                }
            },
            rectangle: {
                showArea: true,
                metric: ['km'],
                drawError: {
                    color: '#b00b00',
                    timeout: 1000
                },
                shapeOptions: {
                    color: '#00A1FF'
                }
            },
            circle: {
                showArea: true,
                metric: ['km'],
                drawError: {
                    color: '#b00b00',
                    timeout: 1000
                },
                shapeOptions: {
                    color: '#00A1FF'
                }
            },
            marker: false,
            circlemarker: false
        }
    });
    map.addControl(draw_control);
    //draw_control.hide(); // we will show it again when control needs to be active

    // isoline tools do not rely on Leaflet Draw plugin, so we just hijack the container to add new features
    const leaflet_draw_toolbar = document.querySelector('.leaflet-draw-toolbar');
    const draw_isodistance = document.createElement('a');
    draw_isodistance.className = 'leaflet-draw-draw-isodistance';
    draw_isodistance.id = 'draw_isodistance';
    draw_isodistance.setAttribute('href', '#');
    draw_isodistance.setAttribute('title', 'Select data by defining a maximum travel distance');
    draw_isodistance_span = document.createElement('span');
    draw_isodistance_span.className = 'sr-only';
    draw_isodistance_span.innerText = 'Select data by defining a maximum travel distance';
    draw_isodistance.appendChild(draw_isodistance_span);
    leaflet_draw_toolbar.appendChild(draw_isodistance);
    // prevent click on custom control to be propagated to map
    element = $('#draw_isodistance')[0];
    L.DomEvent.disableClickPropagation(element);
    // callback is registered with all other callbacks
    const draw_isotime = document.createElement('a');
    draw_isotime.className = 'leaflet-draw-draw-isotime';
    draw_isotime.id = 'draw_isotime';
    draw_isotime.setAttribute('href', '#');
    draw_isotime.setAttribute('title', 'Select data by defining a maximum travel time');
    draw_isotime_span = document.createElement('span');
    draw_isotime_span.className = 'sr-only';
    draw_isotime_span.innerText = 'Select data by defining a maximum travel time';
    draw_isotime.appendChild(draw_isotime_span);
    leaflet_draw_toolbar.appendChild(draw_isotime);
    // prevent click on custom control to be propagated to map
    element = $('#draw_isotime')[0];
    L.DomEvent.disableClickPropagation(element);
    // callback is registered with all other callbacks

    // stopping click propagation does not work when click is done on the inactive one
    // => doing the same for the containing toolbar
    L.DomEvent.disableClickPropagation($('.leaflet-draw-toolbar')[0]);

    // Scoring rule selection, controlled by L.control.customscoring control
    L.Control.CustomScoringSelect = L.Control.extend({
        onAdd: function(map) {
            var scoringdiv = L.DomUtil.create('div', 'radioscoring');
            scoringdiv.id = "scoring-select-radio";
            scoringdiv.innerHTML = "<ul>" +
                            '<li class="as-radio">' +
                                '<input class="as-radio__input" type="radio" name="scoring_rule" id="scoring_rule_1" value="scoring_rule_1"' +
                                '<label class="as-caption" for="scoring_rule_1"> Rule 1</label>' +
                            '</li>' +
                            '<li class="as-radio">' +
                                '<input class="as-radio__input" type="radio" name="scoring_rule" id="scoring_rule_2" value="scoring_rule_2"' +
                                '<label class="as-caption" for="scoring_rule_2"> Rule 2</label>' +
                            '</li>' +
                        "</ul>";
            return scoringdiv;
        },

        onRemove: function(map) {
            // Nothing to do here
        }
    });

    L.control.customscoringselect = function(opts) {
        return new L.Control.CustomScoringSelect(opts);
    }

    L.control.customscoringselect({ position: 'bottomleft' }).addTo(map);

    map_register_callbacks();

    ///////////////////
    /* debug section */
    ///////////////////

    /* activate Chrome debugger by uncommenting line below */
    // debugger;
    /* other option : export some key objects to inspect them from browser console using $.exposed.exported_variable_name e.g. etab_category below */
    $.exposed = {
//        client: client,
//        source: source,
//        etab_category: selectedEtablissements
    }
};

// module-internal function
function map_register_callbacks() {

    // Leaflet control button for local info on map click
    $(function(){
        $(".btinfo").on("click",function(){
            tools_info_click($(this));
        });
    });

    // Leaflet control button for distance to... on map click
    $(function(){
        $(".btbbdist").on("click",function(){
            tools_distance_click($(this));
        });
    });

    // Leaflet control button for Leaflet Measure
    $(function(){
        $("a.leaflet-control-draw-measure").on("click",function(){
            tools_measure_click($(this));
        });
    });

    // Leaflet control button for Leaflet Draw
    $(function(){
        $(".btdrawing").on("click",function(){
            tools_draw_click($(this));
        });
    });

    // Custom control to build an isoline based on maximum distance using HERE API (part of the Leaflet Draw group above)
    $(function(){
        $(".leaflet-draw-draw-isodistance").on("click",function(e){
            tools_isoline_distance_click($(this));
        });
    });

    // Custom control to build an isoline based on maximum time using HERE API (part of the Leaflet Draw group above)
    $(function(){
        $(".leaflet-draw-draw-isotime").on("click",function(e){
            tools_isoline_time_click($(this));
        });
    });

    // Custom callback on all Leaflet Draw buttons to disable isoline tools
    $(function(){
        $(".leaflet-draw-draw-polygon, .leaflet-draw-draw-rectangle, .leaflet-draw-draw-circle").on("click",function(){
            tools_draw_standard_tools_click($(this));
        });
    });

    // Leaflet control button for Scoring radio group
    $(function(){
        $(".btscoring").on("click",function(){
            tools_scoring_click($(this));
        });
    });
};

function map_invalidate() {
    if (debug_mode) {
        console.log('calling map_invalidate()');
    }
    setTimeout(function(){ map.invalidateSize() }, 400);
}

function map_relocate() {
    if (debug_mode) {
        console.log('calling map_relocate(', current_scenario['map_config']['center_lat'], current_scenario['map_config']['center_lng'], current_scenario['map_config']['zoom_init'], ')');
    };
    map.flyTo([current_scenario['map_config']['center_lat'], current_scenario['map_config']['center_lng']], current_scenario['map_config']['zoom_init']);
}

// registers the callback to call when a circle/rectangle/polygon has been created using Leaflet Draw
// used as filter for CARTO widgets dataviews
function map_register_shape_filter_creation_callback() {
    map.on(L.Draw.Event.CREATED, function (e, d) {

        var type = e.layerType;
        let drawnShape = e.layer;
        let carto_views_new_filter_mode;

        // store the new shape in a Leaflet layer to keep it visible
        drawn_items.clearLayers();
        drawn_items.addLayer(drawnShape);

        // and use it as the new shape-based selection filter (rectangle, circle, polygon)
        if (type === 'circle') {
            // set new CARTO data views filter mode
            carto_views_new_filter_mode = 'circle'
            // since we changed the filtering mode, we need to refresh filters for all the dataview used by KeyStats and Widgets
            carto_update_views_filter_mode(carto_views_new_filter_mode);
            // get circle data
            var circleCenter = drawnShape.getLatLng();
            var circleRadius = drawnShape.getRadius();
            // and update filter
            const circleData = {
                lat: circleCenter.lat,
                lng: circleCenter.lng,
                radius: circleRadius
            };
            circleFilter.setCircle(circleData);
        } else if (type === 'polygon') {
            // set new CARTO data views filter mode
            carto_views_new_filter_mode = 'polygon'
            // since we changed the filtering mode, we need to refresh filters for all the dataview used by KeyStats and Widgets
            carto_update_views_filter_mode(carto_views_new_filter_mode);
            // get polygon data
            polygonData = drawnShape.toGeoJSON()['geometry'];
            // and update filter
            polygonFilter.setPolygon(polygonData);
        } else if (type === 'rectangle') {
            // set new CARTO data views filter mode
            carto_views_new_filter_mode = 'polygon'
            // since we changed the filtering mode, we need to refresh filters for all the dataview used by KeyStats and Widgets
            carto_update_views_filter_mode(carto_views_new_filter_mode);
            // get rectangle data
            let polygonData = drawnShape.toGeoJSON()['geometry'];
            // and update filter
            polygonFilter.setPolygon(polygonData);
        } else {
            // do nothing
        };
    });
};

// called after a map click when isoline API has returned a JSON shape (tools_isoline_map_click())
// - simplifies the shape if needed (CARTO URL size limit)
// - adds the polygon to the map
// - defines the new polygon as the current CARTO filter (map, widgets, key figures)
function map_draw_isoline(isolineGeojson) {
    var isolineStyle = {
        "fillColor": "#00A1FF",
        "color": "#00A1FF",
        "weight": 4,
        "fillOpacity": 0.5
    };
    var isolineStyleDebug = {
        "fillColor": "#ffaa55",
        "color": "#ffaa55",
        "weight": 2,
        "fillOpacity": 0.1
    };

    // simplify polygon to avoid being over the 2083 max URL length CARTO error when defining a too complex polygon
    var options;
    var simplified = isolineGeojson;

    let tolerance = 0.01;
    let pointCount = isolineGeojson['features'][0]['geometry']['coordinates'][0].length;

    let carto_views_new_filter_mode;

    while (pointCount > 55) {
        tolerance += 0.005;
        options = {tolerance: tolerance, highQuality: true};
        simplified = turf.simplify(isolineGeojson, options);
        pointCount = simplified['features'][0]['geometry']['coordinates'][0].length;
    };

    // display isoline GeoJSON on map
    isolineLayer = L.geoJSON(simplified, {style: isolineStyle});
    drawn_items.addLayer(isolineLayer);
    // show also non simplified contour in debug mode
    if (debug_mode) {
        isolineLayerDebug = L.geoJSON(isolineGeojson, {style: isolineStyleDebug});
        drawn_items.addLayer(isolineLayerDebug);
    };

    // set new CARTO data views filter mode
    carto_views_new_filter_mode = 'polygon'
    // since we changed the filtering mode, we need to refresh filters for all the dataview used by KeyStats and Widgets
    carto_update_views_filter_mode(carto_views_new_filter_mode);

    // get polygon data
    polygonData = simplified['features'][0]['geometry'];

    // and update filter
    polygonFilter.setPolygon(polygonData);
};


export { map, wicket, info_popup, drawn_items,
    map_init, map_invalidate, map_relocate, 
    map_register_shape_filter_creation_callback, map_draw_isoline };

